import React from 'react';
import createReactClass from 'create-react-class';

var ErrandThreadCheckbox = createReactClass({
	render: function () {
		return (
			<input type="checkbox"
				className={this.props.class}
				title={this.props.title}
				checked={this.props.checked}
				disabled={this.props.disabled}
				onChange={this.onClick} />
		);
	},
	onClick: function(e) {
		if(this.props.onChckBoxChange) {
			this.props.onChckBoxChange(this.props.id, e.target.checked);
		}
	},
});
export default ErrandThreadCheckbox;