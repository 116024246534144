import React from 'react';
import createReactClass from 'create-react-class';

var HeaderColumn = createReactClass({
    render: function() {
        return (
            <td style={this.props.extraStyle}>
                <table style={{cellSpacing:"0", cellPadding:"0", border:"0", width:"100%"}}>
                    <tbody>
                        <tr>
                            <th style={{width:"40px",paddingRight:"4px",whiteSpace:"nowrap"}}>
                                {this.props.data.name}
                            </th>
                            <td style={{verticalAalign:"middle"}}>
                                {this.props.data.value}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </td>
        )
    }
});

var ErrandRow = createReactClass({
    renderHeaderRow: function(data, extraStyle) {
        var columnStyle = ''
        var columnCount = data.length;
        return (
            <tr>
                <td style={extraStyle}>
                    <table style={{cellSpacing:"0", cellPadding:"0", border:"0", width:"100%"}}>
                        <tbody>
                            <tr>
                            {
                                data.map(function(item, idx){
                                    return <HeaderColumn key={idx} data={item} extraStyle={(idx < columnCount - 1) ? {padding:"2px",borderRight: "1px solid #B7B7B7"} : {padding:"2px"}}/>;
                                },this)
                            }                        
                            </tr>
                        </tbody>
                    </table>
                </td>
            </tr>
        )
    },
    render: function() {
        var errand = this.props.errandData;
        var addressTo = null;
        var addressCc = null;

        if (errand.to != "") {
            addressTo = this.renderHeaderRow([{name:I("To")+':', value: errand.to}], {borderBottom:"1px solid #B7B7B7"});
        }
        if (errand.cc != "") {
            addressCc = this.renderHeaderRow([{name:I("Copy-to")+':', value: errand.cc}], {borderBottom:"1px solid #B7B7B7"});
        }
        return (
            <div>
                <div style={{marginBottom:"8px"}}>
                    {this.props.printInfo}
                </div>
                <table style={{cellSpacing:"0", cellPadding:"0", width:"100%", border:"1px solid #B7B7B7"}}>
                    <tbody>
                        {this.renderHeaderRow([{name:"#", value: errand.eid}, {name:I("Date")+':', value: errand.created}], {borderBottom:"1px solid #B7B7B7"})}
                        {this.renderHeaderRow([{name:I("From")+':', value: errand.from}], {borderBottom:"1px solid #B7B7B7"})}
                        {addressTo}
                        {addressCc}
                        {this.renderHeaderRow([{name:I("Subject")+':', value: errand.subject}], {})}
                    </tbody>
                </table>
                <div style={{margin:"2px",marginTop:"8px",marginBottom:"16px",pageBreakAfter:"always"}}>
                    <div dangerouslySetInnerHTML={{__html: errand.message}} />
                </div>
            </div>
        )
    }
});

var PrintErrand = createReactClass({
	getInitialState: function() {
		return {
            printInfo: "",
            printContent: ""
        };
	},
	componentWillMount: function(){
        $.get( webserverRoot + "errand/printErrand", {errandList: this.props.errandList.join(',')})
        .done(function(data){
            this.setState({
                printInfo: data["printInfo"],
                printContent: data["content"]
            });
        }.bind(this));
    },
    render: function() {
        var errands = this.state.printContent;
        if (errands.length <= 0)
            return null;

        return(
            <div>
                {
                    errands.map(function(item, idx){
                        return <ErrandRow key={idx} errandData={item} printInfo={this.state.printInfo}/>;
                    }, this)
                }
            </div>
        )
    }
});

export default PrintErrand;
