import React from 'react';
import createReactClass from 'create-react-class';
var Template = createReactClass({
	displayName: "Template",
	render: function(){
		return (
			<div className="list-group-item">
				<h4 className="list-group-item-heading">[{this.props.template.Name}]<i onClick={this.props.onAdd} className="fa fa-plus-circle pull-right"></i></h4>
				<div className="list-group-item-text" dangerouslySetInnerHTML={{__html:this.props.template.Content}}></div>
			</div>
		);
	}
});
export default createReactClass({
	displayName: "TemplateList",
	getInitialState: function(){
		return {
			busy: false,
			templates: []
		};
	},
	componentWillMount: function(){
		if(this.props.templates){
			this.setState({templates:this.props.templates});
		} else {
			this.setState({busy:true});
			return $.get( webserverRoot + 'area/'+this.props.area+'/templates').then(function(data){
					if(this.props.onLoad){
						this.props.onLoad(data.templates);
					}
					this.setState({busy:false,templates:data.templates});
				}.bind(this))
			.fail(function(data){
				this.setState({busy:false});
			}.bind(this));
		}
	},
	render: function(){
		return (
			<div className="panel panel-default" style={{marginTop:'10px',maxHeight: '300px', overflowY: 'auto'}}>
				{this.state.busy?<div className="busy-container"><img src="img/loading-attachment.gif" /></div>:""}
				<div className="list-group">
				{
					this.state.templates.map(function(template,i){
						return (<Template key={i} template={template} onAdd={this.props.onAdd.bind(null,template)}/>);
					}.bind(this))
				}
				</div>
			</div>
		);
	}
});