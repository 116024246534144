import React from 'react';
import createReactClass from 'create-react-class';
import addonsUpdate from 'react-addons-update';
import 'drilldown';
//dev component
import DrilldownDropdown from './DrilldownDropdown';
import BootstrapModal from './BootstrapModal';

var TagItemList = createReactClass({
	getInitialState: function() {
		var height = 600;
		if (this.parentIsSameOrigin()) {
			height = parent.innerHeight - 400
		}
		return {
			selectedTags: this.props.selectedItems.slice(0),
			initiallyToggleDropdown: true,
			tagged: false,
			tagModalHeight: height +'px',
			tagModalLoad: false
		};
	},
	componentWillReceiveProps: function(nextProps) {
		if (this.props.selectedItems != nextProps.selectedItems) {
			this.setState({
				selectedTags: nextProps.selectedItems.slice(0)
			});
		}
	},
	getDefaultProps: function() {
		return {
			mustSelectMinimum: 1, //0 if we can select nothing from the tag list
			selectedItems: []
		}
	},
	parentIsSameOrigin: function(){
		var result = true;
		try {
			result = window.parent.location.host == window.location.host;
		} catch (e) {
			result = false;
		}
		return result;
	},
	handleCancel: function() {
		if(this.state.tagModalLoad) {
			this.refs.modal.close();
			this.setState({"tagModalLoad": false});
		}
		if(this.props.onCancel) {
			this.props.onCancel();
		}
	},
	populateTags: function (items){

	},
	handleModalLoad: function(){
		this.setState({"tagModalLoad": true});
	},
	handleTagKeyDown: function(e){
		e.stopPropagation();
		if(this.state.tagModalLoad) {
			$(".tagWrapper").show();
			$(".tagWrapper .drilldownDropdown").scrollTop(0);
			var $tagSelect = $('li.selectedTag'), $li = $('li');
			if (e.keyCode == 40) {
				$('li:not(:last-child).selectedTag').removeClass('selectedTag').next("ul#drillDownItems li").addClass('selectedTag');
				if ($tagSelect.next("ul#drillDownItems li").length == 0) {
					$li.eq(0).addClass('selectedTag');
				}
			}
			if (e.keyCode == 38) {
				$('li:not(:first-child).selectedTag').removeClass('selectedTag').prev("ul#drillDownItems li").addClass('selectedTag');
				if ($tagSelect.prev("ul#drillDownItems li").length == 0) {
					$li.eq(-1).addClass('selectedTag');
				}
			}
			if (e.keyCode == 27) {
				this.handleCancel();
				this.state.tagged = false;
			}
			if (e.keyCode == 32) {
				if(this.state.initiallyToggleDropdown) {
					$tagSelect.click();
				}else {
					$("#tagToggle_"+this.props.id).click();
				}
			}
			if (e.keyCode == 13 && !this.state.tagged) {
				this.confirmAction(e);
				this.state.tagged = true;
			}
			//FIXME Firefox (headscratcher) scrolling on the background when scroll using up/down arrow
			if($('ul#drillDownItems li.selectedTag').length > 0) {
				$(".tagWrapper .drilldownDropdown").scrollTop($('ul#drillDownItems li.selectedTag').offset().top-$(".tagWrapper .drilldownDropdown").height());
			}
		}
	},
	handleChangeQuickTags: function(tags) {
		var tagHeight = this.state.tagModalHeight;
		var doNotCloseTagInstantly = features['do-not-close-tags-drop-down'];
		if(!doNotCloseTagInstantly) {
			tagHeight = "200px";
		}
		addonsUpdate(this.props.selectedItems, {$set: tags})
		this.setState({initiallyToggleDropdown: doNotCloseTagInstantly, tagModalHeight: tagHeight});
	},
	handleDropDownToggle :function(t) {
		var tagHeight = 600;
		if(this.parentIsSameOrigin()){
			tagHeight = parent.innerHeight - 400
		}
		this.setState({initiallyToggleDropdown: t, tagModalHeight: tagHeight});
	},
	confirmAction: function(event) {
		var tags = [];
		if (this.state.selectedTags.length > 0) {
			this.state.selectedTags.forEach(function(group) {
				group.forEach(function(tag) {
					tags.push(tag.id);
				});
			});
			this.props.onConfirm(tags, this.state.selectedTags);
		} else {
			if(this.props.mustSelectMinimum == 0) {
				this.props.onConfirm([]);
			}
		}
		this.setState({"tagModalLoad": false});
	},
	getUniqueTags: function(items){
		var usedObjects = {};
		for (var i=items.length - 1;i>=0;i--) {
			var so = JSON.stringify(items[i]);
			if (usedObjects[so]) {
				items.splice(i, 1);
			} else {
				usedObjects[so] = true;
			}
		}
		return items;
	},
	renderClassificationMessage: function(){
		if(F("hotkeys")){
			var classificationSubject = "";
			var classificationMessage = "";
			if(this.props.classificationSubject)
				classificationSubject = (<p className="classificationSubject"
					dangerouslySetInnerHTML={{__html: "Subject : " + replacePM(this.props.classificationSubject)}} />);
			if(this.props.classificationMessage){
				classificationMessage = <span>
					<p className="classificationMessage">{this.props.classificationMessage}</p>
					<p className="classificationInstruction">{I("Press enter to answer the errand with the selected classifications. Press escape to cancel.")}</p>
				</span>;
			}
			return(
				<div className="renderClassificationMessage">
					{classificationSubject}
					{classificationMessage}
				</div>
			)
		}else{
			return "";
		}
	},
	renderTagDataHeight: function() {
		var height;
		if(this.props.classificationMessage && document.body.clientHeight < 500){
			height = document.body.clientHeight - 200;
		}else if(document.body.clientHeight < 500){
			height = document.body.clientHeight - 150;
		}else{
			height = this.state.tagModalHeight;
		}
		return height;
	},
	render: function() {
		var tagList = this.getUniqueTags(this.props.items);
		var tagHeight = 600;
		if(this.parentIsSameOrigin()){
			tagHeight = parent.innerHeight - 400
		}
		if(tagList != null && tagList.length > 0) {
			return(
				<BootstrapModal
				ref="modal"
				id={this.props.id}
				confirm={this.props.confirmButtonText}
				cancel={this.props.cancelButtonText}
				onCancel={this.handleCancel}
				onConfirm={this.confirmAction}
				onShown={this.handleModalLoad}
				title={this.props.classificationTitle}>
					<div id={"Dialog"+this.props.id}>
						{this.renderClassificationMessage()}
						<div id="tagData" style={{"height": this.renderTagDataHeight()}}>
							<div className="errand-tag">
								<div className="tagWrapper">
									<DrilldownDropdown
										id={this.props.id}
										customCssClass='relative'
										selectedItems={this.state.selectedTags}
										tags={tagList}
										initiallyToggleDropdown={this.state.initiallyToggleDropdown}
										inputTagClass='errandtags'
										updateSelectedItem={this.handleChangeQuickTags}
										updateDropDownToggle={this.handleDropDownToggle}
										height={tagHeight}
										groupItems={true}
										onKeyDown={this.handleTagKeyDown}
									/>
								</div>
							</div>
						</div>
					</div>
				</BootstrapModal>
			)
		} else {
			return(<div></div>);
		}
	}
});
export default TagItemList;
