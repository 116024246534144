/* TODO: Re design/develop this component for version 5.0 */
import React from 'react';
import ReactDOM from 'react-dom';
import createReactClass from 'create-react-class';

var BootstrapButton = createReactClass({
	render: function() {
			return (
				<a {...this.props}
				href="javascript:;"
				role="button"
				id = {this.props.id + 'btn'}
				className={(this.props.className || '') + ' btn'} />
			);
	}
});
var BootstrapModal = createReactClass({
	getDefaultProps: function() {
		return {
			draggableHandle: '.modal-header, .modal-footer',
			backgroundScroll: false
		};
	},
	// The following two methods are the only places we need to
	// integrate Bootstrap or jQuery with the components lifecycle methods.
	componentDidMount: function() {
		// When the component is added, turn it into a modal
		$(ReactDOM.findDOMNode(this))
		.modal({backdrop: false, keyboard: true, show: false, autoFocus: true, dismiss: "modal"})
		.on("hidden.bs.modal", function() {
			$("body").css("overflow-y", "auto");
			if(this.props.onHidden) {
				this.props.onHidden();
			}
		}.bind(this));
		var bgScroll = "hidden";
		if(this.props.backgroundScroll) {
			bgScroll = "auto";
		}
		$(ReactDOM.findDOMNode(this)).on("shown.bs.modal", function () {
			$("body.modal-open").css("overflow-y", bgScroll);
		}.bind(this));
		// Make it draggable when drag on modal header or body.
		var s = {cursor: "move", handle: this.props.draggableHandle};
		if(this.props.dragHandler) {
			s.drag = this.props.dragHandler;
		}
		this.props.isDraggable ? $(ReactDOM.findDOMNode(this)).draggable(s) : null;
		if(this.props.onShown){
			this.props.onShown();
		}
		this.open();
	},
	componentWillUnmount: function() {
		this.close();
		$(ReactDOM.findDOMNode(this)).off('hidden', this.handleHidden);
		$(ReactDOM.findDOMNode(this)).off('hidden.bs.modal');
	},
	getInitialState: function() {
		return{
			display: 'none',
			marginTop: '',
			marginLeft: '',
			width: ''
		}
	},
	close: function() {
		$(ReactDOM.findDOMNode(this)).modal('hide');
		if(this.props.onClose) {
			this.props.onClose();
		}
	},
	open: function() {
		$(ReactDOM.findDOMNode(this)).modal('show');
	},
	renderButtons:function(){
		if(!this.props.actions){
			//previous codes does not handle actions buttons dynamically
			//this is safety check untill those old codes are change to use dynamic actions buttons
			return;
		}
		return this.props.actions.map(function(action,i){
			return (
				<BootstrapButton
					onClick={action.onClick}
					key={"modalButtons_"+i}
					id = {action.id}
					className={action.className!=""?action.className:"btn-primary btn-sm"}>
					{action.name}
				</BootstrapButton>
			);
		});
	},
	isMobile: function(){
		var mediaQueryString = window.matchMedia("only screen and (max-width: 760px)").matches;
		return mediaQueryString
	},
	render: function() {
		var confirmButton = null;
		var cancelButton = null;
		var saveButton = null;
		var actionButton = null;
		var extraActionButton = null;
		var closeButton = null;
		var customDialogWidth = "";

		if (this.props.confirm) {
			confirmButton = (
				<BootstrapButton
					onClick={this.handleConfirm}
					id = {this.props.id+"Confirm"}
					className="btn-primary btn-sm">
					{this.props.confirm}
				</BootstrapButton>
			);
		}
		if (this.props.cancel) {
			cancelButton = (
				<BootstrapButton onClick={this.handleCancel} id={this.props.id} className="btn-danger btn-sm">
					{this.props.cancel}
				</BootstrapButton>
			);
		}
		if (this.props.close) {
			closeButton = (
				<BootstrapButton onClick={this.close} id={this.props.id} className="btn-danger btn-sm">
					{this.props.close}
				</BootstrapButton>
				)
		}
		if (this.props.save) {
			saveButton = (
				<BootstrapButton onClick="" id={this.props.id} className="btn-success btn-sm">
					{this.props.save}
				</BootstrapButton>
			);
		}
		if (this.props.action) {
			actionButton = (
				<BootstrapButton onClick={this.handleAction} id="action" className="btn-warning btn-sm">
					{this.props.action}
				</BootstrapButton>
			);
		}

		if (this.props.extraAction) {
			extraActionButton = (
				<BootstrapButton onClick={this.handleExtraAction} id="extraAction" className="btn-warning btn-sm">
					{this.props.extraAction}
				</BootstrapButton>
			);
		}
		var style = {
			width: this.props.width,
			height: this.props.height
		};
		if(this.props.customDialogWidth){
			customDialogWidth = this.props.customDialogWidth;
		}else{
			customDialogWidth = "100%";
		}
		var dialogStyle = {};
		if(this.props.centerDialog){
			dialogStyle = {
				overflowY: (this.props.enableScroll ? "scroll" : ""),
				top: (this.isMobile() ? 0 : "10%"),
				left: (this.isMobile() ? 0 : "30%"),
				right: (this.isMobile() ? 0 : null),
				bottom: (this.isMobile() ? 0 : null),
				marginTop: (this.isMobile() ? "0" : "-50px"),
				marginLeft: (this.isMobile() ? "0" : "-50px"),
				position: "fixed",
				width: (this.props.width != undefined ? this.props.width : "100%"),
				height: (this.isMobile() ? null : this.props.height),
			}
		}else{
			dialogStyle = {
				width: this.props.customDialogWidth ? this.props.customDialogWidth : this.props.width,
				height: this.props.height,
			}
		}
		var bodyStyle = {}
		if (this.props.bodyHeight) {
			bodyStyle = {
				overflowY: "auto",
				height:  this.props.bodyHeight,
			}
		}
		return (
			<div className="modal fade in" id={this.props.id} style={dialogStyle}>
				<div className="modal-dialog" style={dialogStyle}>
					<div className="modal-content">
						<div className="modal-header">
							<button
								type="button"
								className="close"
								onClick={this.handleCancel}>
								&times;
							</button>
							<h4>{this.props.title}</h4>
						</div>
						<div className="modal-body" style={bodyStyle}>
							{this.props.children}
						</div>
						<div className="modal-footer" style={{border: 'none'}}>
							{cancelButton}
							{confirmButton}
							{saveButton}
							{actionButton}
							{extraActionButton}
							{closeButton}
							{this.renderButtons()}
						</div>
					</div>
				</div>
			</div>
		)
	},
	handleCancel: function() {
		if (this.props.cancel) {
			this.close();
		}
		if (this.props.onCancel) {
			this.props.onCancel();
		}
		this.close();
	},
	handleConfirm: function() {
		if (this.props.confirm) {
			this.props.onConfirm();
		}
	},
	handleAction: function() {
		if(this.props.onAction){
			this.props.onAction();
		}
	},
	handleExtraAction: function() {
		if(this.props.onExtraAction){
			this.props.onExtraAction();
		}
	}
});
export default BootstrapModal;
