import React from 'react';
import createReactClass from 'create-react-class';
import addonsUdate from 'react-addons-update';

import FileUploader from './FileUploader';

var ErrandNotes = createReactClass({
    getInitialState: function() {
        return {
            noteItems:[],
            currentNote: "",
            currentAttachments: [],
            readyForsave: false,
            saving: false,
            deleting: -1,
            deletingAttachments: -1,
            loadingNotes: false,
            haveNotes: false
        }
    },
    componentDidMount: function(){
        this.state.loadingNotes = true;
        this.setState(this.state);
        this.doLoadNotes().then(function(data){
            data.notes.length >= 1 ? this.state.haveNotes = true : this.state.haveNotes = false
            for(i=0;i<data.notes.length;i++){
                this.state.noteItems.push(data.notes[i]);
            }
            this.state.loadingNotes = false;
            this.setState(this.state);
            if(this.props.notesCounterUpdated) this.props.notesCounterUpdated(this.state.noteItems.length);
        }.bind(this))
        .fail(function(dat){
            this.state.loadingNotes = false;
            this.setState(this.state);
        }.bind(this));
    },
    bytesToSize: function(bytes) {
        var sizes = ['bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes == 0) return 'n/a';
        var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
        if (i == 0) return bytes + ' ' + sizes[i];
        return (bytes / Math.pow(1024, i)).toFixed(1) + ' ' + sizes[i];
    },
    doSaveNotes: function(){
        var attach = [];
        this.state.currentAttachments.map(function(item,i){
            attach.push(item.id);
        });
        return $.post( webserverRoot + 'errand/notes/save',{errand: this.props.errandId,type: this.props.type,text:this.state.currentNote.trim(),attachments:attach.length > 0 ? attach.join(',') : ""});
    },
    doDeleteNotes: function(id){
        return $.post( webserverRoot + 'errand/notes/delete',{errand: this.props.errandId,type: this.props.type,note:id});
    },
    doDeleteAttachements: function(id){
        return $.post( webserverRoot + 'errand/notes/deleteAttachment',{fid: id});
    },
    doLoadNotes: function(){
        return $.post( webserverRoot + 'errand/notes/list',{errand:this.props.errandId,type: this.props.type});
    },
    findItemIndex: function(items,id){
        var found = -1;
        items.map(function(item, i){
            if(item.id==id){
                found = i;
            }
        },this);
        return found;
    },
    handleRemoveNote: function(item){
        this.state.deleting = item[this.props.fields.id];
        this.setState(this.state);
        this.doDeleteNotes(item[this.props.fields.id]).then(function(data){
            this.state.noteItems.splice(this.findItemIndex(this.state.noteItems,item[this.props.fields.id]),1);
            this.state.deleting = -1;
            this.setState(this.state);
            if(this.props.notesCounterUpdated) this.props.notesCounterUpdated(this.state.noteItems.length);
        }.bind(this))
        .fail(function(dat){
            this.state.deleting = -1;
            this.setState(this.state);
        }.bind(this));

    },
    handleSaveNote: function(){
        if(!this.state.readyForsave || this.state.saving){
            return;
        }
        this.state.saving = true;
        this.setState(this.state);
        this.doSaveNotes().then(function(data){
            this.setState({
                noteItems: addonsUdate(this.state.noteItems, {$unshift: [data.note]}),
                saving: false,
                currentNote: "",
                currentAttachments: [],
                haveNotes: true
            });
            if(this.props.notesCounterUpdated) this.props.notesCounterUpdated(this.state.noteItems.length);
        }.bind(this))
        .fail(function(dat){
            this.setState({saving: false});
        }.bind(this));
    },
    handleNoteAttachment: function(){
        console.log('handling note attachment');
    },
    handleChangeNoteText: function(e){
        if(e.target.value != ""){
            this.state.readyForsave = true;
            this.state.currentNote = e.target.value;
        } else {
            this.state.readyForsave = false;
            this.state.currentNote = e.target.value;
        }
        this.setState(this.state);
    },
    loadThreadView: function(errand){
        console.log('load thread view');
    },
    loadNotesView: function(thread){
        console.log('load notes view');
    },
    handleUploadAttachement: function(attachment){
        this.state.currentAttachments.push(attachment);
        this.setState(this.state);
        console.log("handleUploadAttachement",attachment);
    },
    handleCancelAttachement: function(attachment){
        this.state.deletingAttachments = attachment.id;
        this.setState(this.state);

        this.doDeleteAttachements(attachment.id).then(function(data){
            this.state.currentAttachments.splice(this.findItemIndex(this.state.currentAttachments,attachment.id),1);
            this.state.deletingAttachments = -1;
            this.setState(this.state);
        }.bind(this))
        .fail(function(dat){
            this.state.deletingAttachments = -1;
            this.setState(this.state);
        }.bind(this));
    },
    randerEditNotes: function() {
        return (
            <div>
                <div className="row" style={{marginLeft:'0px',marginRight:'0px'}}>
                    <div id="ErrandNotesBoxDiv" className="ErrandNotesBoxDiv col-md-12">
                        <pre className="ErrandNotesBoxPre" id="ErrandNotesBoxPre">
                            <span id="ErrandNotesBoxSpan"></span><br />
                        </pre>
                        <textarea className="ErrandNotesBoxTextfield" id="ErrandNotesBoxTextfield" value={this.state.currentNote} name="ErrandNotesBoxTextfield[new]" onChange={this.handleChangeNoteText} rows="5.000000" cols="100.000000">
                        </textarea>
                        <textarea style={{display:'none'}} name="ErrandNotesBoxTextfield[old]">
                        </textarea>
                    </div>
                </div>
                <div className="row attachment-box" style={{marginLeft:'0px',marginRight:'0px',marginTop:'5px',marginBottom:'5px'}}>
                    <div className="col-md-9">
                        {this.renderAttachments()}
                    </div>
                </div>
                <div className="row buttons-box" style={{marginLeft:'0px',marginRight:'0px'}}>
                    <div className="btn-group pull-right" role="group">
                        <FileUploader uploadTo={this.props.uploadAttachmentRoute} onDeleteUpload={this.handleCancelAttachement} onFileupload={this.handleUploadAttachement} />
                        <a className={"btn btn-success" + (this.state.readyForsave?"":" disabled")} onClick={this.handleSaveNote}><i className={this.state.saving?"fa fa-circle-o-notch fa-spin":"fa fa-save pull-left"}></i> {this.state.saving?I("Saving..."):I("Save")}</a>
                    </div>
                </div>
                <hr className="notes-divider" />
            </div>
        )
    },
    parentIsSameOrigin: function(){
        var result = true;
        try {
            result = window.parent.location.host == window.location.host;
        } catch (e) {
            result = false;
        }
        return result;
    },
    loadView: function(type, agentCanEditCustomerNote){
        //280 is the optimal count so that the list will only have 4 notes before scrollY appear.
        var containerHeight = 280;
        if(this.parentIsSameOrigin() == true){
            var chatIsWithinContainer = window.parent.document.getElementById('CentionContainer');
            if(chatIsWithinContainer){
                // for chat modal / errand notes
                // FIXME 4.5: Should not use CentionContainer clientHeight for this,
                // Should be using window.innerHeight (view port height)
                containerHeight = window.parent.document.getElementById('CentionContainer').clientHeight - 300;
            }else{
                // for acccessing chat direct. Eg : https://localhost/ng/chat
                containerHeight = parent.innerHeight - 280;
            }
        }
        return (
            <div id="ErrandNotesBoxContent">
                {type=="errand" ||  (type=="client" && agentCanEditCustomerNote) ? this.randerEditNotes(): ""}
                <div className="row list-box" style={{marginLeft:'0px',marginRight:'0px', marginTop:'10px', paddingTop:'10px', borderTop:'1px solid #ddd'}}>
                    <ul className="list-group" style={{overflowY:'auto', maxHeight: containerHeight, display: this.state.haveNotes ? 'block' : 'none' }}>
                        {this.renderNoteItems()}
                    </ul>
                </div>
            </div>
        )
    },
    renderNoteItems: function(){
        if(this.state.loadingNotes){
            return (<li><i className="fa fa-circle-o-notch fa-spin"></i>{I("Loading...")}</li>);
        }
        if(this.state.noteItems.length>0){
            return this.state.noteItems.map(function(item,i){
                return (
                    <li key={item[this.props.fields.id]} className="list-group-item">
                        <a className="btn btn-sm btn-danger pull-right" onClick={this.handleRemoveNote.bind(this,item)}><i className={this.state.deleting==item[this.props.fields.id]?"fa fa-circle-o-notch fa-spin":"fa fa-trash-o"}></i></a>
                        <h4 className="list-group-item-heading" dangerouslySetInnerHTML={{__html: item[this.props.fields.note].replace(/\r\n|\r|\n/g, "<br/>")}}></h4>
                        {
                            item.attachments.map(function(file,fileIndex){
                                return (<a href={file.downloadURL} key={"itemAttachment_"+fileIndex} className="btn btn-xs btn-success"><i className="fa fa-paperclip"></i> {file.fileName} <span className="badge">{this.bytesToSize(file.estSize)}</span></a>);
                            },this)
                        }
                        <p className="list-group-item-text">{I("Written by {0} at {1}.").format(item[this.props.fields.writer],item[this.props.fields.writeTime])}</p>
                    </li>
                );
            },this);
        }
    },
    renderAttachments: function(){
        if(this.state.currentAttachments.length>0){
            return this.state.currentAttachments.map(function(item,i){
                return (
                    <span>
                        <span className="tag label label-default">
                            <span><i className="fa fa-paperclip"></i> {item.value}</span>
                            <div className="btn-group btn-group-xs" role="group" aria-label="...">
                                <a onClick={this.handleCancelAttachement.bind(this,item)}><i className={this.state.deletingAttachments==item.id?"fa fa-circle-o-notch fa-spin":"fa fa-remove"}></i></a>
                            </div>
                        </span>
                    </span>
                );
            },this);
        }
    },
    render: function() {

        var type = this.props.type;
        var errand = this.props.errand;
        var text = this.props.text;
        var attachment = this.props.attachment;
        var notes = this.props.notes;
        var list = this.props.list;
        var note = this.props.note;
        return(
            this.loadView(type, F("notes.client.agent-edit"))
        )
    }
});
export default ErrandNotes;
