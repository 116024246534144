import Flux from '../flux';
import Actions from '../actions/workflowErrandActions.js';

 var WorkflowErrandActions = Flux.createStore({
	displayName: 'ErrandCKEStore',
	bindListeners: {
		handleUpdateCKE: Actions.UPDATE_CKE
	},
	state: {
		ctx:{
			ErrandAnswerUpdated: false
		}
	},
	handleUpdateCKE: function( ctx ){
		this.state.ctx.ErrandAnswerUpdated = this.state.ctx.ErrandAnswerUpdated;
	}
});
export default WorkflowErrandActions;