import React from 'react';
import createReactClass from 'create-react-class';
var ImageTag = createReactClass({
	componentWillMount: function(){
		this.setState({src: this.props.src});
		this.setState({buttonName: this.props.buttonName});
		this.setState({onSomeEvent: this.props.onSomeEvent});
	},
	render: function(){
		var outerStyle = {
			verticalAlign: 'middle',
		};
		var id = this.state.buttonName + "Button.icon";
		return(
			<img id={id} onClick={this.state.onSomeEvent} src={this.state.src} alt="" style={outerStyle}></img>							
		);
	}
});
var SpanTag = createReactClass({
	componentWillMount: function(){
		this.setState({buttonName: this.props.buttonName});
		this.setState({onSomeEvent: this.props.onSomeEvent});
	},
	render: function(){
		var outerStyle = {
			verticalAlign: 'middle',
			fontSize: '12px',
			color: '#717171',
			paddingLeft: '5px'
		};
		var id = this.state.buttonName + "Button.label";
		return(
			<span id={id} onClick={this.state.onSomeEvent} style={outerStyle}>{this.state.buttonName}</span>								
		);
	}
});

var TableButton = createReactClass({
	getInitialState: function() {
		return {data: ''};
	},
	handleClick: function(e){
		if(this.props.actionType == "redirect") {
			location.href = this.props.action;
		}
		if(this.props.actionType == "ajax-request") {
			$.get(this.props.action).done(function( data ){
				location.reload();
			}.bind(this)).fail(function(error, a, b) {
				console.log("Error loading JSON: ", error);
			});
			//Mujibur: I will keep change it to props callback later
			//this.props.onButtonClick(this.props.action);
		}
		if(this.props.actionType == "popup") {
			var url = this.props.action;
			if(url) {
				$.colorbox({iframe:true, width:"80%", height:"80%", href:url });
			}
		}
	},
	render: function() {
		var tdStyle = {
			border: '0',
			padding: '0',
			cursor: 'pointer',
		};
		var outerStyle = {
			whiteSpace: 'nowrap',
			border: '0',
			padding: '0',
			verticalAlign: 'middle',
			cursor: 'pointer',
		};
		var id = this.props.buttonName + "Button.colour";
		return ( 
			<table>
				<tbody>
					<tr>
					<td id={id} className={this.props.color} style={tdStyle}>
						<ImageTag onSomeEvent={this.handleClick} src={this.props.src} buttonName={this.props.buttonName} />
					</td>
					<td style={outerStyle}>
						<SpanTag onSomeEvent={this.handleClick} buttonName={this.props.buttonName} />
					</td>
					</tr>
				</tbody>
			</table>
		);
	}
});
export default TableButton;