import React from 'react';
import createReactClass from 'create-react-class';

var DropDownItem = createReactClass({
	displayName:"DropDownItem",
	getInitialState: function(){
		return {checked:false,showActions:false};
	},
	getDefaultProps: function() {
		return {
			itemsLen: 0,
			idPrefix: '',
			selectName: ''
		}
	},
	componentDidMount: function(){
		this.setState({checked:this.props.selected});
	},
	componentWillReceiveProps: function(nextProps) {
		if(nextProps.selected != this.state.checked){
			this.setState({checked:nextProps.selected});
		}
	},
	handleSelect: function(e){
		if(e.target.id == "selectItem"){
			this.props.onSelectSingle(this.props.item[this.props.fields.id]);
		}else{
			this.setState({checked:!this.state.checked});
			if(this.props.onClick){
				this.props.onClick(this.props.item[this.props.fields.id],!this.state.checked);
			}
		}
	},
	handleHoverIn: function(e){
		this.state.showActions=true;
		this.setState(this.state);
	},
	handleHoverOut: function(e){
		this.state.showActions=false;
		this.setState(this.state);
	},
	handleClose: function(){
		if(this.props.selectName){
			setTimeout(function() {
				$('div#'+this.props.selectName).removeClass('open');
			}.bind(this));
		}
	},
	renderActions: function(){
		if(this.state.showActions){
			return this.props.itemActions;
		}
	},
	render: function() {
		var InactiveStyle={};
		var NestedStyle="normalSelect";
		var knowledgeBase = this.props.knowledgeBase;
		if(this.props.nestedClass == "nestedTitle"){
			NestedStyle="multiSelect";
			knowledgeBase = false;
		}
		if (this.props.wantSelectableGroups && this.props.level) {
			NestedStyle += " level" + this.props.level;
		}
		if(this.props.item[this.props.activeWhen] == false){
			InactiveStyle={opacity: '0.4'};
		}
		if(this.props.multiSelect){
			return (
				<li className={NestedStyle}><a style={InactiveStyle} id={this.props.item[this.props.fields.id]} data-qa-id={'QA_' + this.props.item[this.props.fields.id]} onClick={this.handleSelect} onMouseOver={this.handleHoverIn} onMouseLeave={this.handleHoverOut}><i onClick={this.handle} className={this.state.checked ? " fa fa-check-square-o" : "fa fa-square-o"} /> <span id="selectItem" onClick={this.handleClose}>{this.props.item[this.props.fields.name]}</span></a></li>
			);
		}else if(this.props.singleSelect){
			/* Notes: If item id equal to -1, not available, it will automatically checked and changes not allowed */
			if(this.props.item[this.props.fields.id] != '-1'){
				return(
					<li className={NestedStyle}><a style={InactiveStyle} id={this.props.item[this.props.fields.id]} data-qa-id={'QA_' + this.props.item[this.props.fields.id]} onClick={this.handleSelect} onMouseOver={this.handleHoverIn} onMouseLeave={this.handleHoverOut}><i onClick={this.handle} className={this.state.checked ? " fa fa-check-circle-o" : "fa fa-circle-o"} /> {this.props.item[this.props.fields.name]} {knowledgeBase ? "(ID:" +this.props.item[this.props.fields.id].toString().substring(1) +")": ""} </a></li>
				);
			}else{
				return(
					<li className={NestedStyle}><a style={InactiveStyle} id={this.props.item[this.props.fields.id]} data-qa-id={'QA_' + this.props.item[this.props.fields.id]} onClick="" onMouseOver={this.handleHoverIn} onMouseLeave={this.handleHoverOut}><i onClick="" className="fa fa-check-circle-o" /> {this.props.item[this.props.fields.name]}</a></li>
				);
			}
			
		}else{
			return (<li className={NestedStyle}><a style={InactiveStyle} id={this.props.idPrefix + this.props.item[this.props.fields.id]} data-qa-id={'QA_' + this.props.item[this.props.fields.id]} onClick={this.handleSelect} onMouseOver={this.handleHoverIn} onMouseLeave={this.handleHoverOut}>{this.props.item[this.props.fields.name]} {knowledgeBase ? "(ID:" +this.props.item[this.props.fields.id].toString().substring(1) +")": ""}{this.renderActions()}</a></li>);
		}
	}
});
export default DropDownItem;
