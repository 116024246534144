import React from 'react';
import createReactClass from 'create-react-class';
import createFragment from 'react-addons-create-fragment';
import {I} from '../common/v5/config.js';
import DropDownItem from './DropDownItem';

var DropDown = createReactClass({
	displayName:"DropDown",
	getDefaultProps: function() {
		return {
			title: I('Choose'),
			type: "button",
			selectedItems: "",
			class: "",
			currentTitle: "",
			items: [],
			textChooseItem: "",
			showAllSelected: false,
			defaultSelectAll: false,
			defaultSelectNone: false,
			dropdownHeight: 0,
			sort:true, // should the dropdown items sorted
			multiSelect: false,
			groupsOrdered: [], //caller decide how groupBys are sorted
			disabled: false
		}
	},
	getInitialState: function(){
		return {selectedItems:"", singleSelect:false, multiSelect:false, itemsLen:"", class:"", selectNone: false};
	},
	componentDidMount: function(){
		if(this.props.onLoad){
			this.props.onLoad();
		}
		if(this.props.selectedItems){
			this.setState({selectedItems:this.props.selectedItems});
		}
		if(this.props.defaultSelectAll){
			this.handleSelectAll();
		}
		//doing this trick to avoid select all, none and done button misplaced when none selected.
		if(this.props.defaultSelectNone){
			this.handleSelectNone();
			setTimeout(function() {
				$('div#'+this.props.id).removeClass('open');
			}.bind(this));
		}
		this.updateHeight();
	},
	componentWillReceiveProps: function(nextProps) {
		if(nextProps.selectedItems != this.state.selectedItems){
			this.setState({selectedItems:nextProps.selectedItems});
			if (this.props.triggerOnChangeForNewSelectedItems) {
				this.props.onChange(nextProps.selectedItems);
			}
		}
	},
	updateHeight: function() {
		var height = $("#combo"+this.props.id).outerHeight();
		if(typeof height !== 'undefined' &&
			height != this.state.dropdownHeight) {
			if(this.pid) {
				clearTimeout(this.pid);
			}
			this.pid = setTimeout(function() {
				// layout trashing is needed here because this component
				// require render to be executed first as the Done button
				// need height reference from total items before it which
				// only available after render. Without this, the initial
				// render will have improper Done button position. However,
				// this doesn't seem to be proper style. Done button appear
				// after items and it should already known any height before
				// it. Further thought require for this.
				this.setState({dropdownHeight: height});
			}.bind(this), 500);
		}
	},
	componentWillUnmount: function() {
		if(this.pid) {
			clearTimeout(this.pid);
		}
	},
	componentDidUpdate: function(prevProps,prevState){
		$("#comboStatic"+this.props.id).width($("#combo"+this.props.id).width());
		// var height = $("#combo"+prevProps.id).outerHeight();
		// this.state.dropdownHeight = height;
		this.updateHeight();
	},
	handleItemSelect: function(itemid,isSelected){
		var className = "forwardToExternalDropdown";//FIXME: avoid hardcoded elem class / id.
		var items = this.getSelectedItemsList(itemid,isSelected);
		this.setState({selectedItems:items, class: className});
		this.props.onChange(items);
		if(this.props.multiSelect){
			$('#combo'+this.props.id).parent().toggleClass('open');
		}
	},
	getSelectedItemsList: function(itemid,isSelected){
		if(this.props.multiSelect){
			if(isSelected){
				return this.isSelected(itemid) ? this.state.selectedItems : this.state.selectedItems == "" ? itemid.toString() : this.state.selectedItems + "," + itemid;
			} else {
				if(this.isSelected(itemid)){
					var tmp = this.state.selectedItems.split(",")
					tmp.splice(this.findSelectedIndex(itemid),1);
					return tmp.join(",");
				} else {
					return this.state.selectedItems;
				}
			}
		} else {
			return itemid.toString();
		}
	},
	findSelectedIndex: function(id){
		var found = -1;
		this.state.selectedItems.toString().split(",").map(function(itemid, i){
			if(itemid==id.toString()){
				found = i;
			}
		},this);
		return found;
	},
	isSelected: function(id){
		return this.findSelectedIndex(id) < 0 ? false : true;
	},
	doNothing: function(){},
	renderNestedGroups: function() {
		var list = [];
		var renderItems = function(items, level) {
			var dividerClassName = "divider level" + level;
			items.map(function(item, i) {
				var hasChildren = Array.isArray(item[this.props.nestedGroup]) && item[this.props.nestedGroup].length > 0;
				if (level == 1 || hasChildren) {
					var nestedClass = "";
					var singleSelect = false;
					var multiSelect = false;
					var selected = false;
					var onClick = this.handleSelectGroup;
					var onSelectSingle = null;
					if (this.props.wantSelectableGroups) {
						onClick = this.handleItemSelect;
					} else if (this.props.singleSelect) {
						onClick = this.doNothing();
					}
					if (this.props.wantSelectableGroups) {
						singleSelect = this.props.singleSelect;
						multiSelect = this.props.multiSelect;
						selected = this.isSelected(item[this.props.fields.id]);
						onSelectSingle = this.handleSelectSingle;
					}
					if (level == 1) {
						nestedClass = "nestedTitle";
					}
					if (level == 1 && hasChildren) {
						list.push(<li key={i + "separator"} role="separator" className={dividerClassName}></li>);
					}
					list.push(<DropDownItem
						key={item.Id}
						item={item}
						nestedClass={nestedClass}
						wantSelectableGroups={this.props.wantSelectableGroups}
						level={level}
						activeWhen={this.props.activeWhen}
						fields={this.props.fields}
						onClick={onClick}
						singleSelect={singleSelect}
						multiSelect={multiSelect}
						selected={selected}
						selectName={this.props.id}
						onSelectSingle={onSelectSingle} 
						knowledgeBase={this.props.knowledgeBase}/>);
					if (level == 1 && hasChildren) {
						list.push(<li key={i+"divider"} role="separator" className={dividerClassName}></li>);
					}
					if (hasChildren) {
						renderItems(item[this.props.nestedGroup], level + 1);
					}
				} else {
					list.push(<DropDownItem
						key={item.Id}
						item={item}
						wantSelectableGroups={this.props.wantSelectableGroups}
						level={level}
						activeWhen={this.props.activeWhen}
						fields={this.props.fields}
						onClick={this.handleItemSelect}
						multiSelect={this.props.multiSelect}
						singleSelect={this.props.singleSelect}
						selected={this.isSelected(item[this.props.fields.id])}
						selectName={this.props.id}
						onSelectSingle={this.handleSelectSingle} 
						knowledgeBase={this.props.knowledgeBase}/>);
				}
			}, this);
		}.bind(this);
		renderItems(this.props.items, 1);
		return list;
	},
	renderGroups: function() {
		var self = this;
		var groups = [];
		if(this.props.groupsOrdered && this.props.groupsOrdered.length > 0){
			groups = this.props.groupsOrdered;
		} else {
			groups = this.props.items.sort(function(a,b){
				if (a[self.props.groupBy].toLowerCase() < b[self.props.groupBy].toLowerCase()) return 1;
				if (a[self.props.groupBy].toLowerCase() > b[self.props.groupBy].toLowerCase()) return -1;
				if (a[self.props.groupBy].toLowerCase() == b[self.props.groupBy].toLowerCase()) return 0;
			}).reduce(function(a, b){
				if((!a.slice(-1)[0] && a.slice(-1)[0]!==0) || a.slice(-1)[0] !== b[self.props.groupBy]) {a.push(b[self.props.groupBy]);}
					return a;
			},[]);
		}
		return groups.map(function(group, i){
			return self.renderItems(group);
		});
	},
	sortItems: function(a,b){
		if (a[this.props.fields.name].toString().toLowerCase() > b[this.props.fields.name].toString().toLowerCase()) return 1;
		if (a[this.props.fields.name].toString().toLowerCase() < b[this.props.fields.name].toString().toLowerCase()) return -1;
		if (a[this.props.fields.name].toString().toLowerCase() == b[this.props.fields.name].toString().toLowerCase()) return 0;
	},
	sortItemsWithNumbers: function(a2,b2){
		var a1 = a2[this.props.fields.name].toString().toLowerCase();
		var b1 = b2[this.props.fields.name].toString().toLowerCase();
		var a = parseInt(a1.match(/\d+/g), 10),
		b = parseInt(b1.match(/\d+/g), 10),
		letterA = a1.charCodeAt(0),
		letterB = b1.charCodeAt(0);
		if (letterA > letterB) {
			return 1;
		} else if (letterB > letterA) {
			return -1;
		} else {
			if (a < b) return -1;
			if (a > b) return 1;
			return 0;
		}
	},
	renderItems: function(group){
		var self = this;
		var list = [];
		var sortedItems = this.props.items;
		if(this.props.sort == true){
			if(this.props.items.length > 0){
				sortedItems = this.props.items.sort(function(a,b){
					return self.props.customSort ? self.props.customSort(a,b,self.sortItems) : self.sortItems(a,b);
				});
			}
			else{
				return this.props.items;
			}
		}
		if(group && group != ""){
			list.push(<li role="separator" className="divider"></li>);
			list.push(<li className="dropdown-header nestedTitle">{group}</li>);
			list.push(<li role="separator" className="divider"></li>);
			sortedItems.map(function(item, i){
				if(group && group != "" && group == item[this.props.groupBy]) {
					list.push(<DropDownItem key={i} item={item} activeWhen={this.props.activeWhen} fields={this.props.fields} onClick={this.props.singleSelect? this.doNothing() : this.handleItemSelect} multiSelect={this.props.multiSelect} singleSelect={this.props.singleSelect} selected={this.isSelected(item[this.props.fields.id])} selectName={this.props.id} onSelectSingle={this.handleSelectSingle}/>);
				}
			}, this);
		} else {
			sortedItems.map(function(item, i){
				list.push(<DropDownItem key={i} item={item} activeWhen={this.props.activeWhen} fields={this.props.fields} onClick={this.handleItemSelect} multiSelect={this.props.multiSelect} singleSelect={this.props.singleSelect} selected={this.isSelected(item[this.props.fields.id])} itemsLen={this.state.itemsLen} selectName={this.props.id} onSelectSingle={this.handleSelectSingle} />);
			}, this);
		}
		return list;
	},
	renderList: function() {
		this.state.itemsLen = this.getItemsLen();
		if(this.props.items && this.props.items.length){
			if(this.props.nestedGroup && this.props.nestedGroup!=""){
				return this.renderNestedGroups();
			}
			else if(this.props.groupBy && this.props.groupBy!=""){
				return this.renderGroups();
			} else {
				return this.renderItems("");
			}
		} else {
			return (this.props.children);
		}
	},
	handleSelectAll: function(){
		if(this.props.items && this.props.items.length > 0) {
			var tmp=[];
			if(this.props.nestedGroup && this.props.nestedGroup!=""){
				var selectAll = function(items){
					items.map(function(item, i) {
						if (item[this.props.nestedGroup] && item[this.props.nestedGroup].length > 0) {
							if (this.props.wantSelectableGroups) {
								tmp.push(item[this.props.fields.id]);
							}
							selectAll(item[this.props.nestedGroup]);
						} else {
							tmp.push(item[this.props.fields.id]);
						}
					}, this);
				}.bind(this);
				selectAll(this.props.items);
			} else {
				this.props.items.map(function(item,i){
					tmp.push(item[this.props.fields.id]);
				},this);
			}
			this.setState({selectedItems:tmp.join(",")});
			this.props.onChange(tmp.join(","));
		}
	},
	handleSelectGroup: function(itemid,isSelected){
		if(this.props.items.length > 0) {
			var tmp=this.state.selectedItems && this.state.selectedItems !="" ? this.state.selectedItems.split(",") : [];
			if(this.props.nestedGroup && this.props.nestedGroup!=""){
				this.props.items.map(function(item,i){
					if(item[this.props.fields.id]==itemid){
						if(item[this.props.nestedGroup]){
							item[this.props.nestedGroup].map(function(nestedItem,i){
								if(!this.isSelected(nestedItem[this.props.fields.id])){
									tmp.push(nestedItem[this.props.fields.id]);
								}
							},this);
						}
					}
				},this);
				this.setState({selectedItems:tmp.join(",")});
				this.props.onChange(tmp.join(","));
			}
		}
	},
	showInlineTitle: function(){
		if(this.props.showInlineTitle){
			return(<li key={"showInlineTitle"} className="showInlineTitle"><p id={this.props.id} className="text-center">{this.props.showInlineTitle}</p></li>);
		}
	},
	wantSelectAll: function(){
		if(this.props.wantSelectAll){
			if(this.props.type == "open-multiselect" || this.props.type == "list-multiselect"){
				return (<a id={this.props.id} className="btn btn-default text-center" onClick={this.handleSelectAll}>{I("Select all")}</a>);
			}else{
				return(<li key={"wantSelectAll"}><a id={this.props.id} className="btn btn-default text-center" onClick={this.handleSelectAll}>{I("Select all")}</a></li>);
			}
		}
	},
	handleSelectNone: function(){
		this.setState({selectedItems:"", class:"", selectNone: true});
		if(this.props.onSelectNone){
			this.props.onSelectNone();
		}
		if(this.props.id){
			setTimeout(function() {
				if(this.props.multiSelect == false) {
					$('div#'+this.props.id).removeClass('open');
				}else{
					$('div#'+this.props.id).addClass('open');
				}
			}.bind(this));
		}
		if(this.props.onChange) {
			this.props.onChange("");
		}
	},
	handleSelectSingle: function(itemid){
		var selected = itemid.toString();
		this.setState({selectedItems:selected, class: ""});
		this.props.onChange(selected);
	},
	handleDone: function(){
		this.props.onChange(this.state.selectedItems);
	},
	handleLoseFocus: function(e){
		if(this.props.onBlur){
			this.props.onBlur(this.state.selectedItems);
		}
	},
	handleOnLoad: function(e){
		if(this.props.id){
			$('#combo'+this.props.id).parent().toggleClass('open');
		}
		if(this.props.onLoad){
			this.props.onLoad();
		}
	},
	wantSelectNone: function(){
		if(this.props.wantSelectNone){
			if(this.props.type == "open-multiselect" || this.props.type == "list-multiselect"){
				return(<a id={this.props.id} className="btn btn-default text-center" onClick={this.handleSelectNone}>{I("Select none")}</a>);
			}else{
				if(this.props.items && this.props.items.length > 0){
					return(<li key={"wantSelectNone"} id="wantSelectNone"><a id={this.props.id} className="btn btn-default text-center" onClick={ this.handleSelectNone}>{this.props.textSelectNone ? this.props.textSelectNone : I("Select none") }</a></li>);
				}else{
					return(<li key={"wantSelectNone"} id="wantSelectNone"><a id={this.props.id} className="btn btn-default text-center" onClick={ this.handleSelectNone}>{I("Select none")}</a></li>);
				}
			}
		}
	},
	wantDeselect: function(){
		if(this.props.wantDeselect){
			return(<li key={"wantDeselect"}><a id={this.props.id} className="btn btn-default text-center" onClick={this.handleSelectNone}>{I("Select none")}</a></li>);
		}
	},
	wantShowDone: function(){
		// var dropdownHeight = "";
		// if(this.state.dropdownHeight != undefined){
		// 	dropdownHeight = this.state.dropdownHeight;
		// }else {
		// 	dropdownHeight = $('#combo'+this.props.id).outerHeight();
		// }
		var dropdownWidth = $('#combo'+this.props.id).outerWidth();
		var totalTops = 0, marginTopOffset;
		if (this.props.wantSelectNone) {
			totalTops++;
		}
		if (this.props.wantSelectAll) {
			totalTops++;
		}
		if (this.props.showInlineTitle) {
			totalTops++;
		}
		if (this.props.wantDeselect && this.props.items &&
			this.props.items.length > 0) {
			totalTops++;
		}
		if (totalTops > 0) {
			marginTopOffset = totalTops*28+2;
		} else {
			if (this.state.dropdownHeight >= 6) {
				marginTopOffset = -6;
			} else {
				marginTopOffset = 0;
			}
		}
		var comboBottomStyle = {
			marginTop: (this.state.dropdownHeight+marginTopOffset)+'px',
			position: 'absolute',
			width: dropdownWidth+'px'
		}
		if(this.props.wantShowDone){
			return(
				<ul key={"comboStaticBottom"+this.props.id} id={"comboStaticBottom"+this.props.id} className={"dropdown-menu"+(this.props.align=="right"?" dropdown-menu-right":"")} aria-labelledby="dropdownMenu1" style={comboBottomStyle}>
					<li key={"wantShowDone"}><a id={this.props.id} className="btn btn-default text-center" onClick={this.handleDone}>{I("Done")}</a></li>
				</ul>
				);
		}
	},
	getItemsLen: function(){
		var itemsLen = this.props.items ? this.props.items.length : 0;
		if(this.props.nestedGroup && this.props.nestedGroup!="" &&
			this.props.items != null){
			itemsLen = 0;
			var countItems = function(items) {
				items.map(function(item, i) {
					if (item[this.props.nestedGroup] && item[this.props.nestedGroup].length > 0) {
						if (this.props.wantSelectableGroups) {
							itemsLen += 1;
						}
						countItems(item[this.props.nestedGroup]);
					} else {
						itemsLen += 1;
					}
				}, this);
			}.bind(this);
			countItems(this.props.items);
		}
		return itemsLen;
	},
	showTitle: function(){
		if(this.props.fixedTitle){
			return this.props.fixedTitle;
		}
		var title = "";
		var selectedItemsLen = this.state.selectedItems == "" ? 0 : this.state.selectedItems.split(",").length;
		var itemsLen = this.getItemsLen();
		var selectedTitleList = [];

		if(itemsLen==0){
			if(this.props.currentTitle && this.props.name != ""){
				return this.props.currentTitle;
			}else if (this.props.name && this.props.name != ""){
				return this.props.textNoItem;
			}
		}else{
			if(selectedItemsLen <= 0){
				if (this.props.name && this.props.name != ""){
					if(this.props.modifyTitle != undefined && !this.props.modifyTitle)
						return this.props.name;
					else
						if(this.props.type == "open-multiselect" || this.props.type == "list-multiselect"){
							return this.props.textNoItemSelected;
						}else{
							if(!this.state.selectNone) {
								return this.props.textChooseItem;
							}else{
								return this.props.textNoItemSelected;
							}
						}
				}
			}
		}
		if(this.props.multiSelect && selectedItemsLen > 1){
			if(itemsLen > 0) {
				if(itemsLen == selectedItemsLen){
					return I("All");
				}
				if(itemsLen > selectedItemsLen){
					var arraySelectedItem = this.state.selectedItems.split(',');
					if(this.props.showAllSelected){
						arraySelectedItem.map(function(itemId, i){
							this.props.items.map(function(item,i){
								if(item[this.props.fields.id] == itemId){
									selectedTitleList.push(item[this.props.fields.name]);
								}
							},this);
						},this);
							return selectedTitleList.join(", ");
					}else{
						return I("Multiple");
					}
				}
			}
		} else {
			if(selectedItemsLen == 0){
				if(this.props.textChooseItem){
					return this.props.textChooseItem;
				}
				return I("Choose ...");
			}
			if(itemsLen > 0) {
				if(this.props.nestedGroup && this.props.nestedGroup!=""){
					var findTitle = function(items) {
						for (var i = 0; i < items.length; i++) {
							var item = items[i];
							if (item[this.props.nestedGroup] && item[this.props.nestedGroup].length > 0) {
								var t = findTitle(item[this.props.nestedGroup]);
								if (t) {
									return t;
								}
							}
							if (item[this.props.fields.id].toString() == this.state.selectedItems) {
								if(this.props.knowledgeBase){
									return item[this.props.fields.name] + " (ID:" + this.state.selectedItems.substring(1)+ ")";
								}else{
									return item[this.props.fields.name]; 
								}
							}
						}
					}.bind(this);
					title = findTitle(this.props.items);
				} else {
					this.props.items.map(function(item,i){
						if(item[this.props.fields.id].toString()==this.state.selectedItems){
							title = item[this.props.fields.name]
						}else if(itemsLen > 1){
							//nothing
						}else{
							title = this.props.currentTitle;
						}
					},this);
				}
			}
		}
		return !title || title=="" ? this.props.currentTitle : title;
	},
	renderDropDown: function(type){
		var title = this.showTitle();
		title === "" ? title = I('Sort') : title = this.showTitle();
		var maxLength = 0;
		if(this.props.type != "open-multiselect" && this.props.type != "list-multiselect") {
			if ($(window).width() < 768) {
				maxLength = 40;
			}else if ($(window).width() >= 768 &&  $(window).width() <= 992) {
				maxLength = 110;
			}else if ($(window).width() > 992 &&  $(window).width() <= 1200) {
				maxLength = 20;
			} else {
				maxLength = 30;
			}

			if (maxLength > 0 && title.length > maxLength) {
				title = title.substring(0, maxLength) + "...";
			}
		}
		if(this.props.type == "icon"){
			return (
				<div type="button" className="errand-sprite dd-icon dropdown-toggle" data-toggle="dropdown">
				</div>
			);
		}else if(this.props.type == "select"){
			return(
				<p style={{ margin: '0px 10px'}} data-toggle="dropdown">
					<a id={this.props.id} className="underlink" title=""><span id={this.props.name}> {title}</span></a>
				</p>
			);
		}else if(this.props.type == "link"){
			return(
				<a href="#" className="underlink" onClick={this.props.onLinkClick ? this.props.onLinkClick : '' } data-toggle="dropdown">{title}</a>
			);
		}
		else if(this.props.type == "open-multiselect" || this.props.type == "list-multiselect"){
			var titleArr = title.split(',');
			if(titleArr.length > 0){
				var selectedTitle = [];
				titleArr.map(function(title, i){
					selectedTitle.push(<li key={i}>{title}</li>);
				}, this);
			}
			return(
				<div id="selectedItems">
					{this.props.name != ""?this.props.textSelectedItem:""}
					<ul>{selectedTitle}</ul>
				</div>
				);
		}else {
			return (
				[<button key={"dropdown"} title={this.showTitle()} disabled={this.props.disabled} type="button" className={"btn btn-sm btn-default dropdown-toggle "+this.state.class} data-toggle="dropdown" 
					onClick={this.handleOnLoad}
					data-qa-id={'QA_dropdown_button_title'}>
					{title}
				</button>,
				<button key="dropdown1" disabled={this.props.disabled} className="btn btn-sm btn-default dropdown-toggle" type="button" id="dropdownMenu1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true" 
					onClick={this.handleOnLoad}
					data-qa-id={'QA_dropdown_button_icon'}>
					<span className="caret"></span>
				</button>]
			);
		}

	},
	render: function() {
		var totalTopCount = 0, marginTop;
		if (this.props.wantSelectNone) {
			totalTopCount++;
		}
		if (this.props.wantSelectAll) {
			totalTopCount++;
		}
		if (this.props.showInlineTitle) {
			totalTopCount++;
		}
		if (this.props.wantDeselect && this.props.items &&
			this.props.items.length > 0) {
			totalTopCount++;
		}
		if (totalTopCount > 0) {
			marginTop = totalTopCount*28+8;
		} else {
			marginTop = 0;
		}

		var style = {
			height: 'auto',
			maxHeight: '400px',
			overflowX: 'hidden',
			marginTop: marginTop+'px'
		};

		if(this.props.type == "link"){
			return(
				<div id={this.props.id} className={"btn-group MultiPurposeDropdown "+this.props.class} data-toggle="tooltip" title={this.props.tooltip}>
					<span className="dataTitle">{this.props.name.toUpperCase()}</span> : {this.renderDropDown(this.props.type)}
					<ul id={"comboStatic"+this.props.id} className={"dropdown-menu"+(this.props.align=="right"?" dropdown-menu-right":"")} aria-labelledby="dropdownMenu1">
						{ this.showInlineTitle() }
						{ this.wantSelectAll() }
						{ this.wantSelectNone() }
						{ (this.props.items && this.props.items.length > 0 ? this.wantDeselect() : "") }
					</ul>
					<ul id={"combo"+this.props.id} className={"dropdown-menu"+(this.props.align=="right"?" dropdown-menu-right":"")} aria-labelledby="dropdownMenu1" style={style}>
						{ this.renderList() }
					</ul>
				</div>
			);
		}
		else if(this.props.type == "open-multiselect"){
			return (
				<div id="col-md-12 openMultiSelect">
					<div className="col-md-7" style={{paddingLeft: '0px', paddingRight: '0px'}}>
						<div className="col-md-12">
							<div className="open-multiselect" id={this.props.id} tabIndex="-100" onBlur={this.handleLoseFocus}>
								{ this.renderList() }
							</div>
							<div className="dropdown-options"></div>
							{ this.wantSelectAll() }
							{ this.wantSelectNone() }
						</div>
					</div>
					<div className="col-md-5">
						{this.renderDropDown(this.props.type)}
					</div>
				</div>
				);
		} else if(this.props.type == "list-multiselect"){

			return (
				<div id="openMultiSelect">
					<div id={this.props.id} className="list-multiselect" onBlur={this.handleLoseFocus}>
						{ this.renderList() }
					</div>
					<div className="dropdown-options">
					{ this.wantSelectAll() }
					{ this.wantSelectNone() }
					</div>
				</div>
				);
		} else if(this.props.type == "list-multiselect"){
			return (
				<div id="openMultiSelect">
					<div id={this.props.id} className="list-multiselect" onBlur={this.handleLoseFocus}>
						{ this.renderList() }
					</div>
					<div className="dropdown-options">
					{ this.wantSelectAll() }
					{ this.wantSelectNone() }
					</div>
				</div>
				);
		}else{
			return(
				<div id={this.props.id} className={"btn-group MultiPurposeDropdown "+this.props.class} data-toggle="tooltip" title={this.props.tooltip} tabIndex="-101" onBlur={this.handleLoseFocus}>
					{this.renderDropDown(this.props.type)}
					<ul key={"comboStatic"+this.props.id} id={"comboStatic"+this.props.id} className={"dropdown-menu"+(this.props.align=="right"?" dropdown-menu-right":"")} aria-labelledby="dropdownMenu1">
						{ this.showInlineTitle() }
						{ this.wantSelectAll() }
						{ this.wantSelectNone() }
						{ (this.props.items && this.props.items.length > 0 ? this.wantDeselect() : "") }
					</ul>
					<ul key={"combo"+this.props.id} id={"combo"+this.props.id} className={"dropdown-menu"+(this.props.align=="right"?" dropdown-menu-right":"")} aria-labelledby="dropdownMenu1" style={style}>
						{createFragment({a: <div />, b: this.renderList()})}
					</ul>
					{this.wantShowDone()}
				</div>
			);
		}
	}
});
export default DropDown;
