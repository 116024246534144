import React from 'react';
import ReactDOM from 'react-dom';
import createReactClass from 'create-react-class';
//dev component
import { reEmail } from '../common/helpers';
import WorkflowTable from './WorkflowTable';
import TagItemList from './TagItemList';
import BootstrapModal from './BootstrapModal';
import ErrandNotes from './ErrandNotes';
import ErrandExternalExpert from './ErrandExternalExpert';
import ErrandThreadHistory from './ErrandThreadHistory';
import TemplateList from './TemplateList';
import PrintErrand from '../views/printerrand/printerrand';
import PrintErrandHistory from '../views/printerrand/printerrandhistory';
//views
import EditContactCard from '../views/contactcards/edit';
import Feature from './feature';
import { DataProtection } from './DataProtection';

var SocialMediaOptions = createReactClass({
	getInitialState: function() {
		return {
			value: ""
		};
	},
	handleTextareaChange: function(event) {
		this.setState({value: event.target.value});
	},
	handleButtonClick: function() {
		this.props.onButtonClick(this.props.socialMedia,
			this.props.errandID, this.state.value);
	},
	render: function() {
		return (
			<table style={{width: "98%"}}>
				<tbody>
					<tr><td>
						<span>{I("Message")}:</span>
					</td></tr>
					<tr><td>
						<textarea style={{width: "100%", height: "80px"}}
							value={this.state.value} onChange={this.handleTextareaChange} />
					</td></tr>
					<tr><td style={{textAlign: "center"}}>
						<button type="button" onClick={this.handleButtonClick} className={"btn btn-default"}>
							{this.props.title}
						</button>
					</td></tr>
					</tbody>
				</table>
			);
		}
	});
	var socialMediaDeserialize = function(ef, service) {
		switch(service) {
		case Workflow.Errand.SERVICE_VKONTAKTE:
		case Workflow.Errand.SERVICE_MANUAL_VKONTAKTE:
			return {
				name: "vkontakte",
				capName: "VKontakte",
				urlProfilePrefix: "https://vk.com/",
				errandForm: ef,
				commonAjax: function(errandId, type, dataHandle) {
					$.post(webserverRoot + "errand/" + this.name + "Actions",
						{errand: errandId, action: type})
					.done(function(data) {
						if(dataHandle) {
							dataHandle(data);
						} else {
							console.log('Data: ' + data.action);
						}
					});
				},
				handleHistory: function(eid) {
					this.commonAjax(eid, "history", function(data) {
						if(!data.pm) {
							var url = "https://vk.com/" + data.post;
							window.open(url, 'VKontakte-posts-history', 'scrollbars=yes,menubar=no,toolbar=no,width=1024,height=768');
						} else {
							var message = I("VKontakte private message doesn't support to view history! Only administrator can see the history after logged in to vk.com");
							alert(message);
						}
					});
				},
				handleProfile: function(eid) {
					this.commonAjax(eid, "person", function(data) {
						if(data.personId) {
							var url = this.urlProfilePrefix + data.personId;
							window.open(url, this.name + "-profile", "scrollbars=yes,menubar=no,toolbar=no,width=1024,height=768");
						} else {
							console.log('Error: ', data);
						}
					}.bind(this));
				},
				onSendPMClick: function(sm, eid, text) {
					if(text != "") {
						$.post(webserverRoot + "errand/" + sm.name + "SendPm",
							{errand: eid, message: text})
						.done(function(data) {
							var identifier = $("#" + sm.capName + "SendPM");
							//console.log("Result: " + data.status);
							identifier.dialog('close');
						});
					} else {
						alert(I("Empty message will not be sent to VKontakte."));
					}
				},
				handleSendPM: function(eid) { // general social media
					var identifier = $("#" + this.capName + "SendPM");
					ReactDOM.render(
						React.createElement(SocialMediaOptions, {
							title: I("Send PM"),
							socialMedia: this,
							errandID: eid,
							onButtonClick: this.onSendPMClick
						}), identifier[0]
					);
					identifier.dialog({
						show: {effect: "fadeIn", duration: 800},
						position: {my: "left+800 top+400", at: "center", of: identifier},
						title: I("Send PM"),
						width: 500,
						height: 200,
						overflow: "auto",
						closeOnEscape: true,
						closeText: I("Press Esc(Shortcut) to close")
					});
				},
				handleLike: function(eid) {
					this.commonAjax(eid, "like");
				},
				handleUnlike: function(eid) {
					this.commonAjax(eid, "unlike");
				},
				handleDelete: function(eid) {
					this.commonAjax(eid, "delete");
				},
				handleDeleteAnswer: function(eid) {
					$.post(webserverRoot + "errand/" + this.name + "AnswerActions",
						{errand: eid, types: "deleteAnswer", message: ""})
					.done(function(data) {
						console.log("Result: " + data.status);
						alert(I("Delete request has been sent."));
					});
				},
				handleUpdateAnswer: function(eid) {
					var identifier = $("#" + this.capName + "UpdateAnswer");
					ReactDOM.render(React.createElement(SocialMediaOptions, {
						title: I("Update answer"),
						socialMedia: this,
						errandID: eid,
						onButtonClick: function(sm, eid, txt) {
							if(txt != "") {
								$.post(webserverRoot + "errand/" + sm.name + "AnswerActions",
									{errand: eid, types: "updateAnswer", message: txt})
								.done(function(data) {
									if(data.status) {
										//console.log("Result: " + data.status);
										sm.errandForm.ckeChangeTxt(txt);
									} else if(data.error && data.error != "") {
										alert("Error Update Answer: " + data.error);
									}
									identifier.dialog("close");
								});
							} else {
								alert(I("Message can not be empty"));
							}
						}}), identifier[0]
					);
					identifier.dialog({
						title: I("Update answer"),
						show: {effect: "fadeIn", duration: 800},
						position: {my: "left+800 top+400", at: "center", of: identifier},
						width: 500,
						height: 200,
						overflow: 'auto',
						OnEscape: true,
						closeText: I("Press Esc(Shortcut) to close")
					});
				},
			};
		case Workflow.Errand.SERVICE_FACEBOOK:
		case Workflow.Errand.SERVICE_MANUAL_FACEBOOK:
			return {
				name: "facebook",
				capName: "Facebook",
				urlProfilePrefix: "https://www.facebook.com/"
			};
		case Workflow.Errand.SERVICE_INSTAGRAM:
			return {
				name: "instagram",
				capName: "Instagram",
				urlProfilePrefix: "https://www.instagram.com/",
				commonAjax: function(errandId, type, dataHandle) {
					$.post(webserverRoot + "errand/" + this.name + "Actions",
						{errand: errandId, action: type})
					.done(function(data) {
						if(dataHandle) {
							dataHandle(data);
						} else {
							console.log('Data: ' + data.action);
						}
					});
				},
				handleLike: function(eid) {
					this.commonAjax(eid, "like");
				},
				handleUnlike: function(eid) {
					this.commonAjax(eid, "unlike");
				},
				handleDelete: function(eid) {
					this.commonAjax(eid, "delete");
				},
			}
		case Workflow.Errand.SERVICE_TWITTER:
		case Workflow.Errand.SERVICE_MANUAL_TWITTER:
			return {
				name: "twitter",
				capName: "twitter",
				urlProfilePrefix: "https://twitter.com/",
				errandForm: ef,
				commonAjax: function(errandId, type, dataHandle) {
					$.post(webserverRoot + "errand/" + this.name + "Actions",
						{errand: errandId, action: type})
					.done(function(data) {
						if(dataHandle) {
							dataHandle(data);
						} else {
							console.log('Data: ' + data.action);
						}
					});
				},
				handleHistory: function(eid) {
					this.commonAjax(eid, "history", function(data) {
						if(!data.pm) {
							if(data.customer != "" && data.postId != ""){
								var url = this.urlProfilePrefix + data.customer + "/status/" + data.postId;
								window.open(url, 'twitter-posts-history', 'scrollbars=yes,menubar=no,toolbar=no,width=1024,height=768');
							}else{
								var msg = I("Twitter post ID and user name not found");
								alert(msg);
							}
						} else {
							var message = I("Twitter private message doesn't support to view history! Only administrator can see the history after logged in to twitter.");
							alert(message);
						}
					}.bind(this));
				},
				handleProfile: function(eid) {
					this.commonAjax(eid, "profile", function(data) {
						if(data.customer) {
							if(data.customer != ""){
								var url = this.urlProfilePrefix + data.customer;
								window.open(url, this.name + "-profile", "scrollbars=yes,menubar=no,toolbar=no,width=1024,height=768");
							}else{
								var msg = I("Twitter post user name not found");
								alert(msg);
							}
						} else {
							console.log('Error: ', data);
						}
					}.bind(this));
				},
				onSendPMClick: function(sm, eid, text) {
					if(text != "") {
						$.post(webserverRoot + "errand/" + sm.name + "SendPm",
							{errand: eid, message: text})
						.done(function(data) {
							var identifier = $("#" + sm.capName + "SendPM");
							console.log("Result: " + data.status);
							identifier.dialog('close');
						});
					} else {
						alert(I("Empty message will not be sent to Twitter."));
					}
				},
				handleSendPM: function(eid) {
					var identifier = $("#" + this.capName + "SendPM");
					$.post(webserverRoot + "errand/" + this.name + "Actions", {errand: eid, action:"checkFriendship"})
					.done(function(res){
						if(typeof res.status != 'undefined' && res.status){
							ReactDOM.render(
								React.createElement(SocialMediaOptions, {
									title: I("Send PM"),
									socialMedia: this,
									errandID: eid,
									onButtonClick: this.onSendPMClick
								}), identifier[0]
							);
							identifier.dialog({
								show: {effect: "fadeIn", duration: 800},
								title: I("Send PM"),
								width: 500,
								height: 200,
								overflow: "auto",
								closeOnEscape: true,
								closeText: I("Press Esc(Shortcut) to close")
							});
						}else{
							alert(I("This customer is not following your admin account, so twitter API will be unable to send a direct message."));
						}
					}.bind(this))
					.fail(function(err){
						console.debug("Error:", err);
					});
				},
				handleLike: function(eid) {
					this.commonAjax(eid, "like");
				},
				handleUnlike: function(eid) {
					this.commonAjax(eid, "unlike");
				},
				handleDelete: function(eid) {
					this.commonAjax(eid, "delete");
				},
				handleDeleteAnswer: function(eid) {
					$.post(webserverRoot + "errand/" + this.name + "AnswerActions",
						{errand: eid, types: "deleteAnswer", message: ""})
					.done(function(data) {
						console.log("Result: " + data.status);
						alert(I("Delete request has been sent."));
					});
				},
			};
		default:
			return {
				name: "socialMedia",
				capName: "SocialMedia",
				urlProfilePrefix: "http://cention.com/"
			};
		}
	};
	var facebookActions = function(errandId, type) {
		$.post( webserverRoot + "errand/facebookActions", {errand: errandId, action: type})
		.done(function( data ){
			console.log('Data: ' + data.action);
		}.bind(this));
	};
	var socialMediaActions = function(ef, errandId, service, type) {
		var sm = socialMediaDeserialize(ef, service);
		if(sm[type]) {
			sm[type](errandId);
		}
	};
	var FacebookOptions = createReactClass({
		getInitialState: function(){
			return{
				fieldValue: ""
			}
		},
		handleTextareaChange: function( event ){
			this.setState({fieldValue: event.target.value});
			this.props.handleTextarea( this );
		},
		handleButtonClick: function(){
			this.props.onButtonClick( this );
		},
		render: function(){
			return(
				<table style={{width: "98%"}}>
					<tr>
						<td>
							<span>{I("Message")}:</span>
						</td>
					</tr>
					<tr>
						<td>
							<textarea style={{width: "100%", height: "80px"}} ref={this.props.fieldId} onChange={this.handleTextareaChange} id={this.props.fieldId} />
						</td>
					</tr>
					<tr>
						<td style={{textAlign: "center"}}>
							<button type="button" onClick={this.handleButtonClick} className={"btn btn-default"}>{this.props.title}</button>
						</td>
					</tr>
				</table>
			);
		}
	});
	export default {
		ERRAND_SRC_CONTEXT_NEW: "New Errands",
		ERRAND_SRC_CONTEXT_MY: "My Errands",
		ERRAND_SRC_CONTEXT_SEARCH: "Search Errands",
		ERRAND_SRC_CONTEXT_SEARCH_ANSWER: "Search Answer",
		ERRAND_SRC_CONTEXT_CLOSED: "Closed",
		ERRAND_SRC_CONTEXT_MANUAL: "Manual Errand",
		ERRAND_SRC_CONTEXT_ANSWER: "Answer Errand",
		DATE_NOT_SET: "1970/01/01",
		getCacheWorkflowSettings: function(){
			var WorkflowSettings = localStorage.getItem("WorkflowSettings");
			if(WorkflowSettings !== ""){
				return JSON.parse( WorkflowSettings );
			}
			return [];
		},
		getCacheForwardToAreaList: function(){
			var parseJson = this.getCacheWorkflowSettings();
			return parseJson ? parseJson.forwardToAreas : [];
		},
		getCacheUserFolders: function(){
			return this.getCacheWorkflowSettings() ? this.getCacheWorkflowSettings().userFolders : [];
		},
		optionalClassification: function(){
			return this.getCacheWorkflowSettings() ? this.getCacheWorkflowSettings().mustConfirmNoClassificationJavascript : "";
		},
		forceClassification: function(){
			return this.getCacheWorkflowSettings() ? this.getCacheWorkflowSettings().mustConfirmNoClassificationCention : "";
		},
		getCacheMustConfirmDelete: function(){
			return this.getCacheWorkflowSettings() ? this.getCacheWorkflowSettings().mustConfirmDelete : "";
		},
		getAgentCanPutBackErrands: function(){
			return this.getCacheWorkflowSettings() ? this.getCacheWorkflowSettings().agentCanPutBackErrands: "";
		},
		getCacheBackTo: function(){
			var backTo = localStorage.getItem("backTo");
			return backTo == "" ? "new" : backTo;
		},
		getLocalAreaIdsByErrand: function( errandIds ){
			var areaIds = [];
			var localeCacheErrands = localStorage.getItem("errandList");
			var errandLists = JSON.parse( localeCacheErrands );
			errandLists.forEach(function(errand, i){
				if( this.containsElement(errandIds, errand.id) ){
					if( !this.containsElement(areaIds, errand.areaId) )
						areaIds.push(parseInt(errand.areaId, 10));
				}
			}.bind(this));
			return areaIds;
		},
		containsElement: function(a, v) {
			for (var i = 0; i < a.length; i++) {
				if (a[i] === v) {
					return true;
				}
			}
			return false;
		},
		fetchAreaTags: function( areaIds ){
			return $.post( webserverRoot + 'errand/fetchAreaTags',{areas: areaIds.join()});
		},
		getAreaTagsFromResp: function( datas ){
			var areaTagsContent = {};
			datas.mcam.channels.forEach(function( d ){
				if( d.type == "Result" && d.id == "FetchAreaTags" ){
					if(typeof d.content != undefined && d.content != ""){
						areaTagsContent = JSON.parse( d.content );
						localStorage.setItem("areatags", d.content);
					}
				}
			}.bind(this));
			return areaTagsContent;
		},
		confirmation: function( msg ){
			return window.confirm(msg);
		},
		getErrandsForPrint: function( url ){
			return $('<iframe name="jqiframe" height="500" width="630" border="0" scrolling="yes" id="jqiframe" src="'+ url +'"></iframe>').promise();
		},
		fetchAreaNotification: function(selectedArea){
			return $.post( webserverRoot + "errand/fetchAreaConfirmation", {area: selectedArea});
		},
		fetchMock: function(mockData){
		    return new Promise(function (resolve, reject) {
		      setTimeout(function () {
		        resolve(mockData);
		      }, 2500);
		    });
		},
		replyToConfirmation: function( replyTo, msg, needReplyTo ){
			if(replyTo != "") {
				return true;
			}else {
				if(typeof needReplyTo != 'undefined' && !needReplyTo){
					return true;
				}
				if(this.confirmation(msg)) {
					return true;
				}
			}
			return false;
		},
		closeErrand: function(answer,params,cb){
			var closeErrandCallback = function(parameters){
				$.post( webserverRoot + "errand/closeErrand", parameters)
				.done(function(data){
					if(cb != undefined) { cb() }
				}.bind(this));
			};
			if(this.replyToConfirmation(params.update_to, I("Are you sure you wish to close the errand without selecting a reply-to address?"))) {
				this.askForClassification(answer,params,closeErrandCallback);
			}
		},
		closeErrands: function(items,cb){
			var CloseErrands = function(tags) {
				var update_tags = "";
				if (typeof tags === "number"){
					update_tags = tags.toString();
				} else if (tags.length > 0){
					update_tags = tags.join(',');
				}
				$.post( webserverRoot + 'errand/closeErrands', {list:items.errandListToClose.join(','), update_tags: update_tags})
				.done(function() {
					if(cb != undefined) { cb() }
				}.bind(this));
				if(this.renderedTag) {
					this.renderedTag.refs.modal.close();
				}
			}.bind(this)
			if( items.errandListToClose.length > 0){
				if( this.forceClassification() ){
					var areaIds = items.areaIds;
					this.fetchAreaTags( areaIds)
					.then(function( data ){
						var closeTags = [];
						var areaTags = this.getAreaTagsFromResp(data);
						for (var aTags in areaTags) {
							for(var tag in areaTags[aTags].normal_tags){
								if( areaTags[aTags].normal_tags.hasOwnProperty( tag ) ) {
									closeTags.push(areaTags[aTags].normal_tags[tag]);
								}
							}
						}
						if(closeTags.length > 0){
							if(this.renderedTag) {
								ReactDOM.unmountComponentAtNode(document.getElementById("ErrandActionsTagging"));
							}
							this.renderedTag = ReactDOM.render(
								React.createElement(TagItemList,{
									id: "tagClose",
									items : closeTags,
									selectedItems: [],
									confirmButtonText: I("Confirm"),
									cancelButtonText: I("Cancel"),
									classificationTitle: I("You have not chosen a classification for selected errands."),
									classificationMessage: I("You can navigate the list by pressing the up and down arrows and select a classification by pressing space."),
									onConfirm: CloseErrands
								}),
								document.getElementById('ErrandActionsTagging')
							);
							this.renderedTag.refs.modal.open();
						} else {
							CloseErrands([]);
						}
					}.bind(this));
				}else{
					CloseErrands([]);
				}
			}
		},
		deleteErrands: function(items,cb){
			var openedByExternal = (typeof items.openedByExternal !== "undefined") ? items.openedByExternal : "false";
			var DeleteErrands = function(tags) {
				var deleted_tags = "";
				if (typeof tags === "number"){
					deleted_tags = tags.toString();
				} else if (tags.length > 0){
					deleted_tags = tags.join(',');
				}
				$.post( webserverRoot + 'errand/deleteErrands', {list:items.errandListToDelete.join(','), delete_tag: deleted_tags, openedByExternal: openedByExternal})
				.done(function() {
					if(cb != undefined) { cb() }
				}.bind(this));
				if(this.renderedTag) {
					this.renderedTag.refs.modal.close();
				}
			}.bind(this)
			if( items.errandListToDelete.length > 0
				&& (!F("mustConfirmDelete") || (F("mustConfirmDelete") && this.confirmation(I("Are you sure you want to delete the selected errand?"))))) {
				if( this.forceClassification() ){
					var areaIds = items.areaIds;
					this.fetchAreaTags( areaIds)
					.then(function( data ){
						var deleteTags = [];
						var areaTags = this.getAreaTagsFromResp(data);
						for (var aTags in areaTags) {
							for(var tag in areaTags[aTags].delete_tags){
								if( areaTags[aTags].delete_tags.hasOwnProperty( tag ) ) {
									deleteTags.push(areaTags[aTags].delete_tags[tag]);
								}
							}
						}
						if(deleteTags.length > 0){
							if(this.renderedTag) {
								ReactDOM.unmountComponentAtNode(document.getElementById("ErrandActionsTagging"));
							}
							this.renderedTag = ReactDOM.render(
								React.createElement(TagItemList,{
									id: "tagDelete",
									items : deleteTags,
									selectedItems: [],
									confirmButtonText: I("Confirm"),
									cancelButtonText: I("Cancel"),
									classificationTitle: I("You have not chosen a classification for selected errands."),
									classificationMessage: I("You can navigate the list by pressing the up and down arrows and select a classification by pressing space."),
									onConfirm: DeleteErrands
								}),
								document.getElementById('ErrandActionsTagging')
							);
							this.renderedTag.refs.modal.open();
						}else{
							DeleteErrands([]);
						}
					}.bind(this));
				} else {
					DeleteErrands([]);
				}
			}
		},
		handlePrintAction: function() {
			// Use section-to-print for printing of specific content of a page
			var contentToPrint = document.getElementById("content-to-print").cloneNode(true);
			var sectionToPrint = document.getElementById("section-to-print");

			if (!sectionToPrint) {
				sectionToPrint = document.createElement("div");
				sectionToPrint.id = "section-to-print";
				document.body.appendChild(sectionToPrint);
			}
			sectionToPrint.innerHTML = "";
			sectionToPrint.appendChild(contentToPrint);

			// Hide the container from printed out as empty space
			$('head').append('<style type="text/css">@media print {#container {display: none;}}}</style>');
			window.print();
			setTimeout(function(){
				// Reset the style to allow window print function to work as usual
				$('head').append('<style type="text/css">@media print {#container {display: block;}}}</style>');
				sectionToPrint.innerHTML = "";
			}, 1000);
		},
		printErrands: function(errandListToPrint, action, cb){
			if(this.printDialog) {
				ReactDOM.unmountComponentAtNode(document.getElementById("errand_action_modal_container"));
			}
			var previewContent = React.createElement((action=="single" ? PrintErrand : PrintErrandHistory), {errandList: errandListToPrint});
			this.printDialog = ReactDOM.render(
				<BootstrapModal
					id="errand_action_modal"
					width = "650px"
					height = "auto"
					actions = {[{id:"print", name:I("Print errand"), className:"btn-primary btn-sm", onClick:this.handlePrintAction}]}
					close = "Cancel"
					centerDialog = {true}
					isDraggable = {true}
					bodyHeight = "400px"
					title={I("Print")}>
						<div id="content-to-print" style={{fontFamily:'monospace'}}>
							{previewContent}
						</div>
				</BootstrapModal>,
				document.getElementById('errand_action_modal_container'));
			$('#errand_action_modal').modal();

			// Auto trigger to print upon dialog popped up
			setTimeout(this.handlePrintAction, 1000);
		},
		queueToMe: function(errandListToQueue,cb){
			if( errandListToQueue.length > 0){
				$.post( webserverRoot + 'errand/queueErrandToUser', {list:errandListToQueue.join(',')})
				.done(function() {
					if(cb != undefined) { cb() }
				}.bind(this));
			}
		},
		moveErrandsToFolder: function(selectedFolder, errandListToMove, force, openedByExternal, cb, parameters) {
			if( errandListToMove.length > 0){
				if (!parameters) {
					parameters = {};
				}
				parameters.list = errandListToMove.join(',');
				parameters.folder = selectedFolder;
				parameters.force = force;
				parameters.openedByExternal = openedByExternal;
				$.post( webserverRoot + "errand/changeErrandFolder", parameters)
				.done(function(data){
					if(cb != undefined) {
						data.mcam.channels.forEach(function( d ){
							if(typeof d.type != undefined && d.type == "Result" ){
								var cfs = JSON.parse(d.content);
								cb(cfs);
							}
						});
					}
				}.bind(this));
			}
		},
		forwardErrandsToArea: function(selectedArea, errandListToForward, force, openedByExternal, cb, parameters, needReplyTo) {
			var forwardConfirmMessage = I("Are you sure you want to forward the selected errand to the area?");
			var replyToEmptyMessage = I("Are you sure you want to forward the errand without selecting a reply-to address?");
			if( errandListToForward.length > 0
				&& (!F("mustConfirmMoveErrand") || (F("mustConfirmMoveErrand") && this.replyToConfirmation( parameters.update_to, replyToEmptyMessage, needReplyTo ) && this.confirmation( forwardConfirmMessage )))) {
				var send_notification = false;
				var ConfirmForwardToAreaNotificationMessage = I('Do you want to send a notification to the agents working with this area?');
				this.fetchAreaNotification()
				.then(function( result ){
					if(result)
						send_notification = window.confirm(ConfirmForwardToAreaNotificationMessage);
					if (!parameters) {
						parameters = {};
					}
					parameters.list = errandListToForward.join(',');
					parameters.area = selectedArea;
					parameters.force = force;
					parameters.send_notification = send_notification;
					parameters.openedByExternal = openedByExternal;
					$.post( webserverRoot + "errand/changeErrandArea", parameters)
					.done(function(data){
						if(cb != undefined) { cb() }
					}.bind(this));
				}.bind(this));
			}
		},
		forwardErrandsToAgent: function(selectedAgent, errandListToForward, openedByExternal, cb, parameters) {
			var forwardConfirmMessage = I("Are you sure you want to forward the errand to the agent?");
			var replyToEmptyMessage = I("Are you sure you want to forward the errand without selecting a reply-to address?");
			if( errandListToForward.length > 0
				&& (!F("mustConfirmMoveErrand") || (F("mustConfirmMoveErrand") && this.replyToConfirmation( parameters.update_to, replyToEmptyMessage ) && this.confirmation( forwardConfirmMessage )))) {
				if (!parameters) {
					parameters = {};
				}
				parameters.list = errandListToForward.join(',');
				parameters.agent = selectedAgent;
				parameters.openedByExternal = openedByExternal;
				parameters.update_salutation = 0;
				parameters.update_signature = 0;
				$.post( webserverRoot + "errand/changeErrandAgent", parameters)
				.done(function(data){
					data.mcam.channels.forEach(function(d) {
						if(typeof d.type != undefined && d.type == "Result") {
							var returnMsg = JSON.parse(d.content);
							if(returnMsg.error && returnMsg.error.length > 0) {
								alert(returnMsg.error);
							}
						}
					}.bind(this));
					if(cb != undefined) { cb() }
				}.bind(this));
			}
		},
		handleFbHistory: function(errandId, service) {
			switch(service) {
			case Workflow.Errand.SERVICE_FACEBOOK:
			case Workflow.Errand.SERVICE_MANUAL_FACEBOOK:
				$.post( webserverRoot + "errand/facebookActions", {errand:errandId, action: 'history'})
				.done(function( data ){
					if(! data.pm){
						var url = "https://www.facebook.com/" + data.post;
						window.open(url, 'facebook-posts-history', 'scrollbars=yes,menubar=no,toolbar=no,width=1024,height=768');
					}else{
						var message = I("Facebook private message doesn't support to view history! Only administrator can see the history after logged in to facebook.");
						alert(message);
					}
				});
				break;
			default:
				socialMediaActions(this, errandId, service, "handleHistory");
			}
		},
		handleFbProfile: function(errandId, service) {
			switch(service) {
			case Workflow.Errand.SERVICE_FACEBOOK:
			case Workflow.Errand.SERVICE_MANUAL_FACEBOOK:
				$.post(webserverRoot + "errand/facebookActions", {errand: errandId, action: 'person'})
				.done(function(data) {
					if(data.personId) {
						var url = "https://www.facebook.com/" + data.personId;
						window.open(url, 'facebook-profile', 'scrollbars=yes,menubar=no,toolbar=no,width=1024,height=768');
					} else {
						console.log('Error: ', data);
					}
				});
				break;
			default:
				socialMediaActions(this, errandId, service, "handleProfile");
			}
		},
		handleFbLike: function(errandId, service) {
			switch(service) {
			case Workflow.Errand.SERVICE_FACEBOOK:
			case Workflow.Errand.SERVICE_MANUAL_FACEBOOK:
				/*Mujibur: I kept this for future use*/
				//item.setState({visibleFacebookLike: !item.state.visibleFacebookLike})
				facebookActions(errandId, 'like');
				break;
			default:
				socialMediaActions(this, errandId, service, "handleLike");
			}
		},
		handleFbUnlike: function(errandId, service) {
			switch(service) {
			case Workflow.Errand.SERVICE_FACEBOOK:
			case Workflow.Errand.SERVICE_MANUAL_FACEBOOK:
				facebookActions(errandId, 'unlike');
				break;
			default:
				socialMediaActions(this, errandId, service, "handleUnlike");
			}
		},
		handleFbHide: function(errandId, service) {
			switch(service) {
			case Workflow.Errand.SERVICE_FACEBOOK:
			case Workflow.Errand.SERVICE_MANUAL_FACEBOOK:
				facebookActions(errandId, 'hide');
				break;
			default:
				socialMediaActions(this, errandId, service, "handleHide");
			}
		},
		handleFbUnhide: function(errandId, service) {
			switch(service) {
			case Workflow.Errand.SERVICE_FACEBOOK:
			case Workflow.Errand.SERVICE_MANUAL_FACEBOOK:
				facebookActions(errandId, 'unhide');
				break;
			default:
				socialMediaActions(this, errandId, service, "handleUnhide");
			}
		},
		handleFbDelete: function(errandId, service) {
			switch(service) {
			case Workflow.Errand.SERVICE_FACEBOOK:
			case Workflow.Errand.SERVICE_MANUAL_FACEBOOK:
				facebookActions(errandId, 'delete');
				break;
			default:
				socialMediaActions(this, errandId, service, "handleDelete");
			}
		},
		handleFbSendPM: function(errandId, service) {
			switch(service) {
			case Workflow.Errand.SERVICE_FACEBOOK:
			case Workflow.Errand.SERVICE_MANUAL_FACEBOOK:
				ReactDOM.render(
					React.createElement(FacebookOptions, {
						fieldId: "pmMessageText",
						title: I("Send PM"),
						onButtonClick: function(item){
							if(item.state.fieldValue != ""){
								$.post( webserverRoot + "errand/facebookSendPm", {errand: errandId, message: item.state.fieldValue})
								.done(function(data){
									console.log("Result: " + data.status);
									$('.ui-dialog').hide()
									$('#FacebookSendPM').dialog('close');
								}.bind(this));
							}else{
								alert(I("Empty message will not be sent to Facebook."));
							}
						}.bind(this),
						handleTextarea: function(){
						}.bind(this)
					}),document.getElementById("FacebookSendPM")
				);
				$('#FacebookSendPM').dialog({
					show: {effect: "fadeIn", duration: 800},
					position: {
						my: 'top-230',
						at: 'left',
						of: $('#SendPMButton')
					},
					title: I("Send facebook PM"),
					width: 500,
					height: 180,
					overflow: 'auto',
					closeOnEscape: true,
					closeText: I("Press Esc(Shortcut) to close")
				});
				break;
			default:
				socialMediaActions(this, errandId, service, "handleSendPM");
			}
		},
		handleFbRating: function(errandId, service) {
			switch(service) {
			case Workflow.Errand.SERVICE_FACEBOOK:
			case Workflow.Errand.SERVICE_MANUAL_FACEBOOK:
				var columns = [
					{header: I("Created"), key: "created"},
					{header: I("Message"), key: "message"},
					{header: I("Ratings"), key: "rating", type:"image"}
				];

				$.post( webserverRoot + "errand/facebookFetchRatings", {errand: errandId})
				.done(function(data){
					if(data.list != undefined){
						ReactDOM.render(<WorkflowTable columns={columns} selectedItems={""} dataSource= {data.list} />, document.getElementById("FacebookRatings"));
					}
					$('#FacebookRatings').dialog({
						show: {effect: "fadeIn", duration: 800},
						position: {
							my: 'top-200',
							at: 'left',
							of: $('#RatingButton')
						},
						title: I("Review rating for facebook page"),
						height: 500,
						width: 600,
						closeOnEscape: true,
						overflow: 'auto',
						closeText: I("Press Esc(Shortcut) to close")
					});
				});
			default:
				socialMediaActions(this, errandId, service, "handleRating");
			}
		},
		handleFbDeleteAnswer: function(errandId, service) {
			switch(service) {
			case Workflow.Errand.SERVICE_FACEBOOK:
			case Workflow.Errand.SERVICE_MANUAL_FACEBOOK:
				$.post(webserverRoot + "errand/facebookAnswerActions", {errand: errandId, types: "updateAnswer", message: ""})
				.done(function(data) {
					console.log("Result: " + data.status);
					alert(I('Delete request has been sent.'));
				}.bind(this));
				break;
			default:
				socialMediaActions(this, errandId, service, "handleDeleteAnswer");
			}
		},
		handleFbUpdateAnswer: function(errandId, service) {
			switch(service) {
			case Workflow.Errand.SERVICE_FACEBOOK:
			case Workflow.Errand.SERVICE_MANUAL_FACEBOOK:
				ReactDOM.render(
					React.createElement(FacebookOptions, {
						fieldId: "updateAnswerMessage",
						title: I("Update answer"),
						onButtonClick: function(item) {
							var txt = item.state.fieldValue;
							if(txt != "") {
								$.post( webserverRoot + "errand/facebookAnswerActions", {errand: errandId, types: "updateAnswer", message: txt})
								.done(function(data) {
									//console.log("Result: " + data.status);
									this.ckeChangeTxt(txt);
									$('#FacebookUpdateAnswer').dialog('close');
								}.bind(this));
							} else {
								alert(I("Empty message will not be sent to Facebook."));
							}
						}.bind(this),
						handleTextarea: function(){
						}.bind(this)
					}),document.getElementById("FacebookUpdateAnswer")
				);
				$('#FacebookUpdateAnswer').dialog({
					show: {effect: "fadeIn", duration: 800},
					position: { my: "left+800 top+400", at: "center", of: $('#FacebookUpdateAnswer') },
					title: I("Answer has been updated on facebook."),
					width: 500,
					height: 200,
					overflow: 'auto',
					closeOnEscape: true,
					closeText: I("Press Esc(Shortcut) to close")
				});
				break;
			default:
				socialMediaActions(this, errandId, service, "handleUpdateAnswer");
			}
		},
		handleTwitterRetweet: function( errandId ){
			$.post( webserverRoot + "errand/twitterActions", {errand: errandId, action: 'retweet'})
			.done(function(data){
				console.log("Result: " + data.result);
				alert(I("Retweet sent successfully."));
			}.bind(this));
		},
		handleLinkedinConnect: function( errandId ){
			facebookActions(errandId, 'delete')
		},
		returnErrandToInbox: function( errandListToForward, errandId,
			openedByExternal, callback ){
			$.post( webserverRoot + "errand/returnErrands",
				{list: errandListToForward.join(','), update_id: errandId,
				openedByExternal: openedByExternal})
			.done(function(data){
				callback(data);
			}.bind(this));
		},
		areaLevel1Tags: function(area) {
			if(area.normal_tags) {
				return area.normal_tags;
			}else{
				return [];
			}
		},
		askForClassification: function(answer,parameters, callback){
			var CONTEXT_MANUAL = this.ERRAND_SRC_CONTEXT_MANUAL;
			if((!this.optionalClassification() && !this.forceClassification()) ||
				(this.optionalClassification() && parameters.update_tags))
			{
				callback(parameters);
				return;
			}
			var confirmMessage = (answer.currentContextName == CONTEXT_MANUAL || answer.associated_list.length == 0
			? I('Are you sure you wish to close the errand without choosing a classification?')
			: I('You are about to close errands without tags, do you want to proceed?'));
			var classifyCallback = function( highTagAreas ) {
				var preSelectedTags = [];
				var errands = [];
				var current = 0;
				var ErrandTags = (<div>EmptyContainer</div>);
				var showDialog = function( errand ) {
					var nextErrand = function( hideDialog ) {
						if( answer.currentContextName == CONTEXT_MANUAL ) {
							callback(parameters);
						} else {
							current++;
							if( current >= errands.length ) {
								callback(parameters);
								if( hideDialog ) {
									if(ErrandTags.refs && ErrandTags.refs.modal){
										ErrandTags.refs.modal.close();
									}
								}
							} else {
								showDialog(errands[current]);
								return false;
							}
						}
					}.bind(this);
					var handleSelectClick = function( selected_tags, preTags ) {
						if( answer.currentContextName == CONTEXT_MANUAL ||
							errand.id == answer.currentContext.id )
						{
							parameters.update_tags = selected_tags.join(',');
						} else {
							var tags = 'tags_' + errand.id;
							parameters[tags] = [];
							parameters[tags] = selected_tags.join(',');
						}
						preSelectedTags = preTags;
						$('#ErrandActionsTagging > .modal.fade').modal('hide');
						return nextErrand(true);
					};

					var title = (answer.currentContextName == CONTEXT_MANUAL
					? I('You have not selected a classification for this errand.')
					: I('Errand {0} in area {1}').format( errand.data.displayId, answer.areaData.name) );
					if( answer.areaData){
							if( this.areaLevel1Tags(answer.areaData).length > 0 && answer.selected_normal_tags.length == 0 ) {
								if(this.renderedTag) {
									ReactDOM.unmountComponentAtNode(document.getElementById("ErrandActionsTagging"));
								}
								this.renderedTag = ReactDOM.render(
									React.createElement(TagItemList,{
										id: "NoClassificationSelectedDialog",
										selectedItems: preSelectedTags,
										items : answer.areaData.normal_tags,
										confirmButtonText: I("Confirm"),
										cancelButtonText: I("Cancel"),
										classificationTitle: title,
										classificationMessage: I("You can navigate the list by pressing the up and down arrows and select a classification by pressing space."),
										classificationSubject: errand.data.subject,
										onConfirm: handleSelectClick,
										onCancel: answer.onCancelTag,
										useShortcut: F("hotkeys")
									}),
									document.getElementById('ErrandActionsTagging')
								);
								this.renderedTag.refs.modal.open();
						} else {
							nextErrand(true);
						}
					}else{
						console.log("no tags for this area for classification");
						callback(parameters);
					}
				}.bind(this);
				errands.push(answer.currentContext);
				if( answer.associated_list.length > 0 ) {
					answer.associated_list.forEach(function(errand) {
						errands.push(errand);
					});
				}
				errands = errands.filter(function(item, pos) {
					return errands.indexOf(item) == pos;
				}.bind(this));
				showDialog(errands[current]);
			}.bind(this);

			if( answer.currentContextName == CONTEXT_MANUAL ) {
				if(this.areaLevel1Tags(answer.areaData).length > 0) {
					//classifyCallback(answer.areaData.normal_tags);
					if(this.forceClassification()) {
						return classifyCallback(answer.areaData.normal_tags);
					} else if(this.optionalClassification() &&
						!this.confirmation(confirmMessage)) {
						// if not confirm then return without execute callback
						// which execute update/send errand command.
						return;
					}
				}
				return callback(parameters);
			}
			var areas = [];
			areas.push(answer.currentContext.data.area);
			if(answer.associated_list && answer.associated_list.length > 0){
				answer.associated_list.forEach(function(errand) {
					if( $.inArray(errand.data.area, areas) == -1 )
						areas.push(errand.data.area);
				});
			}
			var handleAreasLoaded = function(areasLoaded) {
				var highTagAreas = [];
				areas.forEach(function(id) {
					var area = areasLoaded;
					if( area.normal_tags != null && area.normal_tags.length > 0 ) {
						highTagAreas.push(area.id);
					}
				});
				if( this.forceClassification() )
					return classifyCallback(highTagAreas);
				else if(highTagAreas.length > 0){
					if(this.optionalClassification() && this.confirmation(confirmMessage))
						return callback(parameters);
					else
						return;
				}
				else{
					return callback(parameters);
				}
			}.bind(this);
			this.loadAreas(areas,answer.areaDatas,handleAreasLoaded);
		},
		loadAreas: function(list,existingAreas,callback){
			var areasLoaded = false;
			var needLoad = false;
			var areas = [];
			list.forEach(function(id) {
				if( !existingAreas[id] ) {
					areas.push(id);
					needLoad = true;
				}
			});
			if(needLoad){
				var data = {
					areas: areas[0]
				};
				$.post( webserverRoot + "errand/areaData", data)
				.done(function(areaJson){
					var areaData = "[{}]";
					areaJson.mcam.channels.forEach(function( d ){
						if(typeof d.type != undefined && d.type == "Result" ){
							areaData = JSON.parse(d.content);
						}
					});
					callback(areaData);
				}.bind(this))
				.fail(function(jqXHR, textStatus, errorThrown) {
					var text = [
						"We're sorry, an error occurred.",
						"",
						"Message: " + errorThrown,
						"URL: " + webserverRoot + "errand/areaData",
						"",
						jqXHR.responseText
					];
					alert(text.join("\n"));
				});
			}else{
				callback(existingAreas);
			}
		},
		translate: function(arg, onsuccess) {
			var msgUnavailable = I("We're sorry, translation service is not available at the moment.");
			var translateUrl = window.location.protocol + "//" +window.location.hostname + "/Cention/translate";
			// TODO showTranslatingSpinner();
			$.ajax(translateUrl, {
				"type": "POST",
				"data": JSON.stringify(arg),
				"contentType": "application/json",
				"complete": function(jqXHR, status) {
					// TODO hideTranslatingSpinner();
					if (status === "success") {
						var r = JSON.parse(jqXHR.responseText);
						if (r.error != null) {
							alert(I('Language detection: ') + r.error);
							return;
						}
						if (onsuccess) {
							onsuccess(r.texts);
						}
					} else if (status === "error") {
						alert(msgUnavailable);
					} else if (status === "notmodified") {
						// We shouldn't reach here
					}
				}
			});
		},
		sendAnswer: function(answer,params,cb) {
			var replyToEmptyMessage = I("Are you sure you wish to send the errand without selecting a reply-to address?");
			var sendErrandAttempts = 1;
			var sendErrandCallback = function(parameters){
				if( F("translation") && (answer.translateFromLang != "" && answer.translateToLang != "") ){
					var that = this;
					that.translate({
							texts: [
								"Automatic translation from " + window.Translation.toLangText + " to " + window.Translation.fromLangText + ":",
								"Original text:",
								"Question automatically translated from " + window.Translation.fromLangText + " to " + window.Translation.toLangText + ":",
								"Subject"
							],
							from: "en",
							to: answer.translateFromLang
						},
						function (translated) {
							that.translate({
									"texts": [params.update_answer],
									"from": answer.translateToLang,
									"to": answer.translateFromLang
								},
								function (translations) {
									var emphasize = function(text) { return '<u><em>' + text + '</em></u>'; };
									var paragraphSeparator = "<br />\n<br />\n<p>";
									var answerWithTranslation = [
										emphasize(translated[0])
										, translations.join(paragraphSeparator)
										, emphasize(translated[1])
										, params.update_answer
										, emphasize(translated[2])
										, translated[3] + ": " + window.Translation.translatedQuestion
										].join(paragraphSeparator);
									parameters.update_answer = answerWithTranslation;
									$.post( webserverRoot + "errand/sendErrand", parameters)
									.done(function(data){
										if(cb != undefined) { cb(true) }
											if( answer.currentContextName == this.ERRAND_SRC_CONTEXT_MANUAL ){
												this.alertSendConfirm(data);
											}
									})
									.fail(function(jqXHR, textStatus, errorThrown) {
										if(sendErrandAttempts < 3) {
											sendErrandAttempts++;
											setTimeout(function() {
												sendErrandCallback(params);
											}, 1000);
										} else {
											var text = [
												"We're sorry, an error occurred.",
												"Would you like to try again?",
												"",
												"This action has been attempted " + sendErrandAttempts + " times.",
												"",
												"Message: " + errorThrown,
												"URL: " + webserverRoot + "errand/sendErrand",
												"",
												jqXHR.responseText
											];
											if(window.confirm(text.join("\n"))) {
												sendErrandAttempts++;
												setTimeout(function() {
													sendErrandCallback(params);
												}, 1000);
											}
										}
									});
								}
							);
						}
					);
				}else{
					$.post( webserverRoot + "errand/sendErrand", parameters)
					.done(function(data){
						if(cb != undefined) { cb(true) }
							if( answer.currentContextName == this.ERRAND_SRC_CONTEXT_MANUAL ){
								this.alertSendConfirm(data);
							}
					}.bind(this))
					.fail(function(jqXHR, textStatus, errorThrown) {
						if(sendErrandAttempts < 3) {
							sendErrandAttempts++;
							setTimeout(function() {
								sendErrandCallback(params);
							}, 1000);
						} else {
							var text = [
								"We're sorry, an error occurred.",
								"Would you like to try again?",
								"",
								"This action has been attempted " + sendErrandAttempts + " times.",
								"",
								"Message: " + errorThrown,
								"URL: " + webserverRoot + "errand/sendErrand",
								"",
								jqXHR.responseText
							];
							if(window.confirm(text.join("\n"))) {
								sendErrandAttempts++;
								setTimeout(function() {
									sendErrandCallback(params);
								}, 1000);
							}
						}
					});
				}
			}.bind(this);
			if((answer.currentContext.data.service == Workflow.Errand.SERVICE_SMS) &&
				(answer.errandInfo.extendedData.data.errand_type == Workflow.Mail.TYPE_SMS) &&
				(answer.errandInfo.extendedData.data.max_sms_size > 0)){
				if(params.update_answer.length > answer.errandInfo.extendedData.data.max_sms_size){
					var exceededSizeAlert = I("Errand has exceeded maximum allowed size of {MAXSIZE} bytes. Current size is {CURRSIZE} bytes.")
						.replace('{MAXSIZE}', answer.errandInfo.extendedData.data.max_sms_size)
						.replace('{CURRSIZE}',params.update_answer.length)
						;
					alert(exceededSizeAlert);
					return;
				}
			}
			else {
				var maxEmailSize = answer.errandInfo.extendedData.data.total_allowed_size;
				var encodingFactor = answer.errandInfo.extendedData.data.encoding_factor;
				if(maxEmailSize > 0	&& encodingFactor > 0){
					var checkQuestion = params.update_question;
					var checkAnswer = params.update_answer;
					var questionLength = (checkQuestion != null)?
						checkQuestion.length: 0;
					var total = params.update_attachment_total +
								(questionLength + checkAnswer.length) *
								encodingFactor;
					if(Math.round(total) > maxEmailSize){
						var exceededSizeAlert = I("Errand has exceeded maximum allowed size of {MAXSIZE} bytes. Current size is {CURRSIZE} bytes.")
							.replace('{MAXSIZE}', maxEmailSize)
							.replace('{CURRSIZE}', Math.round(total))
							;
						alert(exceededSizeAlert);
						return;
					}
				}
			}
			if(answer.forwarded){
				if(params.update_forward == ""){
					this.confirmation(I("You have to fill in an address for Forward to external."));
					return cb(false);
				}
			}
			if(params.update_answer != ""){
				if(this.replyToConfirmation(params.update_to, replyToEmptyMessage)) {
					this.askForClassification(answer,params,sendErrandCallback);
				}else {
					return cb(false);
				}
			}else{
				if(params.update_answer == ""){
					var emptyAnswer = this.confirmation(I("Are you sure you wish to send an empty answer?"));
					if(!emptyAnswer){
						return cb(false);
					}
					if(this.replyToConfirmation(params.update_to, replyToEmptyMessage)) {
						this.askForClassification(answer,params,sendErrandCallback);
					}else {
						return cb(false);
					}
				}
			}
		},
		handleCopyErrandID: function(errandId) {
			var userAgent = window.navigator.userAgent,
			msie = userAgent.indexOf('MSIE '),
			trident = userAgent.indexOf('Trident/');
			if (msie > 0 || trident > 0) {
				window.clipboardData.setData('Text', errandId);
			}else {
				var forClipboard = document.createElement('textarea');
				forClipboard.innerText = errandId;
				document.body.appendChild(forClipboard);
				forClipboard.select();
				if(!document.execCommand) return;
				document.execCommand('copy');
				forClipboard.remove();
			}
		},
		alertSendConfirm: function(data) {
			data.mcam.channels.forEach(function(d) {
				if(typeof d.type != undefined && d.type == "Result") {
					var returnMsg = JSON.parse(d.content);
					if(returnMsg.success) {
						var alertInfo = {};
						alertInfo.actions = [];
						alertInfo.title = I("Message");
						alertInfo.message = returnMsg.message;
						alertInfo.close = I("Close");
						alertInfo.actions.push(
							{
								id:"copyId",
								name:I("Copy Errand ID"),
								className:"btn-primary btn-sm",
								onClick:function(){
									this.handleCopyErrandID(returnMsg.errand);
								}.bind(this)
							}
						);
						this.centionAlert(alertInfo);
					}else {
						alert(returnMsg.error);
					}
				}
			}.bind(this));
		},
		updateAnswer: function(answer, params, callback) {
			var message  = "";
			if( answer.currentContextName != this.ERRAND_SRC_CONTEXT_MANUAL ){
				message = I("Saving errand {0}...").format( params.update_id );
			}
			$.post( webserverRoot + "errand/updateErrand", params)
			.done(function( data ){
				if( answer.currentContextName == this.ERRAND_SRC_CONTEXT_MANUAL ){
					data.mcam.channels.forEach(function(d){
						if(typeof d.type != undefined && d.type == "Result") {
							var returnMsg = JSON.parse(d.content);
							if(returnMsg.error) {
								alert(returnMsg.error);
								callback(returnMsg.error);
							} else {
								callback(returnMsg.result);
							}
						}
					});
				}else{
					callback(message);
				}
			}.bind(this));
		},
		reopenErrand: function( params, callback ){
			var message = I("Reopening errand {0}...").format( params.update_id );
			$.post( webserverRoot + "errand/reopenErrand", params)
			.done(function( data ){
				this.updateErrandCounter(data);
				callback( message );
			}.bind(this));
		},
		resendAnswer: function( params, callback ){
			var message = I("Resending errand {0}...").format( params.update_id );
			$.post( webserverRoot + "errand/resendErrand", params)
			.done(function( data ){
				this.updateErrandCounter(data);
				callback( message );
			}.bind(this));
		},
		publishErrand: function( params, callback ){
			var message = I("Publishing errand {0}...").format( params.errand );
			$.post( webserverRoot + "errand/publishErrand", params)
			.done(function( data ){
				this.updateErrandCounter(data);
				callback( message );
			}.bind(this));
		},
		unpublishErrand: function( params, callback ){
			var message = I("Unpublishing errand {0}...").format( params.errand );
			$.post( webserverRoot + "errand/unpublishErrand", params)
			.done(function( data ){
				callback( message );
			}.bind(this));
		},
		errandSuggestToLibrary: function( params, callback ){
			var message = I("Suggesting errand {0} for Library...").format( params.errand );
			$.post( webserverRoot + "errand/suggestErrandForLibrary", params)
			.done(function( data ){
				this.updateErrandCounter(data);
				callback( message );
			}.bind(this));
		},
		loadModalComponent: function(modalContent,oContent){
			var defaultWidth = "620px";
			var isMobile = this.isMobile();
			if(oContent.customDialogWidth){
				defaultWidth = oContent.customDialogWidth;
			}
			var isDraggable;
			var draggableHandle;
			if(oContent.isDraggable || oContent.isDraggable == undefined){
				isDraggable = true;
			}else{
				isDraggable = false;
			}
			if(oContent.draggableHandle || oContent.draggableHandle == undefined){
				draggableHandle = oContent.draggableHandle;
			}else {
				draggableHandle = ".modal-header, .modal-body, .modal-footer";
			}
			return createReactClass({
				render: function() {
					return(
						<BootstrapModal
						id="errand_action_modal"
						width = {isMobile ? "100%" : defaultWidth}
						height = "auto"
						actions = {oContent.actions}
						centerDialog = {oContent.centerDialog}
						xPosition = {oContent.x+"px"}
						yPosition = {oContent.y+"px"}
						close = {oContent.close}
						isDraggable = {isDraggable}
						draggableHandle = {draggableHandle}
						backgroundScroll = {oContent.backgroundScroll}
						title={oContent.title}>
							<div id="errand_action_modal_content" className="wfDialogContents">
								{modalContent}
							</div>
						</BootstrapModal>
					);
				}
			});
		},
		generateHistoryThreadPopup: function(oContent){
			oContent.title = I("Other Contacts Details");
			oContent.section = "OtherContacts"
			var modalWidth = document.body.clientWidth - 480; //480 is the width of 'Other Contacts' container
			var modalHeight = document.body.clientHeight - 200;
			var otherContactsComponent = (
				<BootstrapModal
				key={(new Date()).getTime()}
				id="historyThreadModal"
				width = {modalWidth}
				height = "auto"
				actions = {oContent.actions}
				centerDialog = {oContent.centerDialog}
				title={oContent.title}>
					<div className="wfDialogContents otherContactModal" style={{"height" : modalHeight }}>
						<ErrandThreadHistory
						key={(new Date()).getTime()}
						section={oContent.section}
						dataSrc={oContent.content.data}
						forwardToExternalMode={oContent.forwardToExternalVisible} />
					</div>
				</BootstrapModal>
			)
			var isChatInSingleWindow = window.location.href.indexOf(centionChatUrl) != -1;
			if(isChatInSingleWindow){
				ReactDOM.render(otherContactsComponent, document.getElementById('chat_action_modal_container'));
			}else{
				ReactDOM.render(otherContactsComponent, document.getElementById('errand_action_modal_container'));
			}
			$('#historyThreadModal').modal();
		},
		manageErrandNotes: function(oContent){
			oContent.title = I("Errand notes");
			oContent.actions = [];
			oContent.customDialogWidth = "600px";
			oContent.centerDialog = true;
			oContent.backgroundScroll = true;
			var ErrandTags = ReactDOM.render(
				React.createElement(
							this.loadModalComponent(
										<ErrandNotes
											id="ErrandNotesBoxContent"
											type="errand"
											errandId={oContent.errandId}
											fields={{id:"id",note:"note",writer:"username",writeTime:"writtenDate"}}
											uploadAttachmentRoute="errand/notes/uploadAttachment"
											notesCounterUpdated={oContent.notesCounterUpdated} />,
										oContent),
							null),
				document.getElementById('errand_action_modal_container')
			);
			$('#errand_action_modal').modal();
		},
		loadTemplates: function(oContent){
			oContent.title = I("Choose templates");
			oContent.actions = [];
			var Templates = ReactDOM.render(
				React.createElement(
					this.loadModalComponent(
						<TemplateList
						id="ErrandChooseTemplates"
						area={oContent.areaId}
						onAdd={function(template){
							oContent.onInsertTemplate(template);
						}}
					/>,
					oContent),
				null),
				document.getElementById('errand_action_modal_container')
			);
			$('#errand_action_modal').modal();
		},
		manageExternalExpertDialog: function(oContent){
			oContent.title = I("External Expert");
			oContent.actions = [];
			var ErrandTags = ReactDOM.render(
				React.createElement(
							this.loadModalComponent(
										<ErrandExternalExpert
											id="ErrandNotesBoxContent"
											type="errand"
											errandId={oContent.errandId}
											fields={{id:"id",note:"note",writer:"username",writeTime:"writtenDate"}}
											uploadAttachmentRoute="errand/notes/uploadAttachment"
											notesCounterUpdated={oContent.notesCounterUpdated} />,
										oContent),
							null),
				document.getElementById('errand_action_modal_container')
			);
			$('#errand_action_modal').modal();
		},
		getWorkflowSettings: function(){
			var workflowSettings = localStorage.getItem('WorkflowSettings');
			if(workflowSettings != "" && workflowSettings != null){
				return JSON.parse( workflowSettings );
			}
				return "";
		},
		renderContactCard: function(EditContactCard,ErrandNotes,oContent){
			return (
				<div>
					<Feature tag="agent.connect-address-to-clients">
						<div id="dialogConnectionList">
							<h4>{I("Contact Card")}</h4>
							<EditContactCard
								groupId={""}
								errandInfo={oContent}
								popup={true}
								clientContactCounterUpdated={oContent.clientContactCounterUpdated}
								handleErrandThreadsUpdated={oContent.handleErrandThreadsUpdated}
							/>
						</div>
					</Feature>
					<Feature tag="notes.client">
						<div>
							<h4>{I("Customer notes")}</h4>
							<ErrandNotes
								id="CustomerNotesBoxContent"
								type="client"
								errandId={oContent.errandId}
								fields={{id:"id",note:"note",writer:"username",writeTime:"writtenDate"}}
								uploadAttachmentRoute= "errand/notes/uploadAttachment"
								notesCounterUpdated={oContent.custNotesCounterUpdated}
								/>
						</div>
					</Feature>
					{!F("data-protection.export") && !F("data-protection.anonymize-by-agent")?null:
						<DataProtection
							errandId={oContent.errandId}/>
					}
				</div>
				)
		},
		manageContactCard: function(oContent){
			oContent.title = I("Contact card");
			oContent.actions = [];
			oContent.customDialogWidth = "600px";
			oContent.centerDialog = true;
			oContent.isDraggable = true;
			oContent.draggableHandle = ".modal-header, .modal-footer, #dialogConnectionList";
			oContent.backgroundScroll = true;
			ReactDOM.render(
				React.createElement(
							this.loadModalComponent(this.renderContactCard(EditContactCard,ErrandNotes,oContent), oContent)),
				document.getElementById('errand_action_modal_container')
			);
			$('#errand_action_modal').modal().addClass('showOverflowY');
		},
		renderExternalExpertQueries: function(content){
			return (
				<div id="ExternalExpertsBox">
				<span dangerouslySetInnerHTML={{__html: content}} />
				<div id="lightStatus" style={{padding: '2px', textAlign: 'center', Zindex: '301', width: '100%', left: '2111px', top: '241px', backgroundColor: 'rgb(153, 255, 153)', display: 'none'}}></div>
				</div>
				)
		},
		manageExternalExpert: function(oContent){
			oContent.title = I("External Expert");
			oContent.actions = [];
			oContent.actions.push({id:"externalExpert",name:I("External Expert"),className:"btn-warning btn-sm",onClick:function(){ oContent.onClickPopup()}});
			if(F("external-experts.show-turn-light-off") && oContent.queryExist == "1") {
				oContent.actions.push({id:"lightOnOff", name:oContent.btnText, className:"btn-warning btn-sm",onClick:function(){ oContent.onClick()}});
			}
			var ErrandTags = ReactDOM.render(
				React.createElement(this.loadModalComponent(this.renderExternalExpertQueries(oContent.content),oContent), null),
				document.getElementById('errand_action_modal_container')
			);
			$('#errand_action_modal').modal();
		},
		updateErrandCounter: function(datas){
			/*
				This function would update the counter for new/my errands on the menu
				The reason we have to use jquery to update it cause Menu.js is not a child component of Errand/Workflow pages,
				and updated counter values result would be received by the errand/workflow page withing the mcam of errand actions.
			*/
			var replaceColor = function(newColor,badge){
				var className = newColor=='#F00' ? "label-danger":"label-primary";
				$(badge).removeClass('label-danger');
				$(badge).removeClass('label-primary');
				$(badge).addClass(className);
			};
			var oNewCounter = this.getErrandCountObject(datas,"New Errands");
			$(oNewCounter.name).text(oNewCounter.count);
			replaceColor(oNewCounter.colour,oNewCounter.name)
			var oMyCounter = this.getErrandCountObject(datas,"My Errands");
			$(oMyCounter.name).text(oMyCounter.count);
			replaceColor(oMyCounter.colour,oMyCounter.name)
		},
		getErrandCountObject: function( datas, hashpath ){
			var errandObject = {count:0,colour:'#DC80000',name: hashpath == this.ERRAND_SRC_CONTEXT_NEW ? "#cntDataNew" : "#cntDataMy"};
			var source = (hashpath == this.ERRAND_SRC_CONTEXT_NEW ? "new" : "my" );
			datas.mcam.channels.forEach(function(d){
				if(typeof d.content != undefined && d.id == source+"-comment-count"){
					var contents = JSON.parse(d.content);
					errandObject.count = contents.count;
					errandObject.colour = contents.colour;
				}
			}.bind(this));
			return errandObject;
		},
		getErrandCount: function( datas, hashpath ){
			return this.getErrandCountObject(datas, hashpath).count;
		},
		isCustomFolder: function(folder) {
			var count = 0;
			folder.forEach(function(f, i) {
				if(f.value != "My Errands") {
					count++;
				}
			}.bind(this));
			if(count > 0) {
				return true;
			}
			return false;
		},
		getUniqueData: function(data){
			var usedObjects = {};
			for (var i=data.length - 1;i>=0;i--) {
				var so = JSON.stringify(data[i]);
				if (usedObjects[so]) {
					data.splice(i, 1);
				} else {
					usedObjects[so] = true;
				}
			}
			return data;
		},
		deviceDetection: function() {
			return (/android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini|windows phone/i.test(navigator.userAgent.toLowerCase()));
		},
		toHumanDate: function(dt){
			var dd = dt.getDate();
			var mm = dt.getMonth()+1;
			var yyyy = dt.getFullYear();
			if(dd<10) { dd='0'+dd }
			if(mm<10) { mm='0'+mm }
			return yyyy+'/'+mm+'/'+dd;
		},
		isValidPhoneNo: function(phoneNo){
			var phn = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g;
			return phn.test(phoneNo);
		},
		isValidEmail: function(emailAddress){
			return reEmail.test(emailAddress);
		},
		showLoadingWorkflowList: function(status) {
			if(status == "show") {
				$("#loadingPreview").show();
				$('#MessageTable').css({ 'opacity' : 0.5 });

			}else {
				$("#loadingPreview").hide();
				$('#MessageTable').css({ 'opacity' : 1 });
			}
		},
		isMobile: function(){
			var mediaQueryString = window.matchMedia("only screen and (max-width: 760px)").matches;
			return mediaQueryString
		},
		centionAlert: function(alertInfo){
			var ErrandTags = ReactDOM.render(
				React.createElement(
							this.loadModalComponent(
										<div id="cention-alert" className="centionAlert">
											{alertInfo.message}
										</div>,
										alertInfo),
							null),
				document.getElementById('errand_action_modal_container')
			);
			$('#errand_action_modal').modal();
		},
		isIEBasedBrowser: function(){
			var userAgent = window.navigator.userAgent,
			msie = userAgent.indexOf('MSIE '),
			trident = userAgent.indexOf('Trident/');
			if (msie > 0 || trident > 0) {
				return true;
			}else {
				return false;
			}
		}(),
		sanitizeHtml: function(html) {
			var doc = document.createElement('div');
			doc.innerHTML = html;
			return doc.innerHTML;
		},
		truncateHtml: function(s, l){
			var m, r = /<([^>\s]*)[^>]*>/g,
			stack = [],
			lasti = 0,
			result = '';
			//for each tag, while we don't have enough characters
			while ((m = r.exec(s)) && l) {
				//get the text substring between the last tag and this one
				var temp = s.substring(lasti, m.index).substr(0, l);
				//append to the result and count the number of characters added
				result += temp;
				l -= temp.length;
				lasti = r.lastIndex;
				if (l) {
					result += m[0];
					if (m[1].indexOf('/') === 0) {
						//if this is a closing tag, than pop the stack (does not account for bad html)
						stack.pop();
					}else if (m[1].lastIndexOf('/') !== m[1].length - 1) {
						//if this is not a self closing tag than push it in the stack
						stack.push(m[1]);
					}
				}
			}
			//add the remainder of the string, if needed (there are no more tags in here)
			result += s.substr(lasti, l);
			//fix the unclosed tags
			while (stack.length) {
				result += '</' + stack.pop() + '>';
			}
			return result;
		}
	}
