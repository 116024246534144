import React, { memo, forwardRef } from 'react'
import { Tooltip as BSTooltip } from 'reactstrap'
import styled from 'styled-components'
import { centionBackgroundGrey, centionGrey } from '../styles/_variables'
import { composeWithDisplayName, createWithMountWhenPropTrue } from './hocs'
import { useDomState } from '../hooks/state'
import { useToggle } from '../hooks/toggle'

const tooltipDelayAttr = { show: 0, hide: 0 }

const TooltipBase = props => (
  <BSTooltip
    autohide={false}
    container='body'
    delay={tooltipDelayAttr}
    hideArrow={true}
    placement='bottom-start'
    trigger='hover'
    {...props}
  />
)

export const Tooltip = createWithMountWhenPropTrue('target')(TooltipBase)

const StyledSpan = styled.span`
  span& {
    color: ${centionGrey};
    span.fa-stack {
      cursor: pointer;
      line-height: 1.5em;
      i.fas.fa-question-circle {
        // color: ${centionBackgroundGrey};
      }
    }
  }
`
const DarkFont = styled.i`
  color: #a3a3a3;
`
const StyledDiv = styled.div`
  font-size: 12px;
  text-align: left;
  overflow: auto;
  padding: 12px;
`
const StyledTooltip = styled(Tooltip)`
  .tooltip {
    .tooltip-inner {
      // background: #fff;
      // border-color: #ccc;
      // border-top-color: initial;
      // border-right-color: rgb(204, 204, 204);
      // border-bottom-color: rgb(204, 204, 204);
      // border-left-color: rgb(204, 204, 204);
      border-radius: 10px;
      // border-top: none;
      // border-top-width: initial;
      // border-top-style: none;
      // border-top-color: initial;
      box-shadow: 2px 3px 5px 1px #0003;
      max-height: 30vh;
      max-width: 30vw;
      white-space: normal;
    }
  }
`
const TriggerElement = forwardRef(({ content }, ref) => {
  return (
    <span ref={ref} className="tooltip-target">
      {content ? content
        : //else default to icon
        <span className="fa-stack">
          <DarkFont className="fas fa-circle fa-stack-1x" />
          <i className="fas fa-question-circle fa-stack-1x" />
        </span>
      }
    </span>
  )
});

const HelperBase = ({ children, className, hideTooltip = false, triggerElement, ...props }) => {
  const [node, callbackRef] = useDomState()
  const [show, onToggle] = useToggle()
  return (
    <StyledSpan className={className ? className + " helper" : "helper"}>
      {/* {<span ref={callbackRef} className="fa-stack">
        <DarkFont className="fas fa-circle fa-stack-1x" />
        <i className="fas fa-question-circle fa-stack-1x" />
      </span>} */}
      <TriggerElement ref={callbackRef} content={triggerElement} />
      {
        !hideTooltip &&
        <StyledTooltip
          autohide={false}
          isOpen={show}
          target={node}
          toggle={onToggle}
          className={className}
        >
          <StyledDiv>{children}</StyledDiv>
        </StyledTooltip>
      }
    </StyledSpan>
  )
}

const Helper = composeWithDisplayName(
  'Helper',
  createWithMountWhenPropTrue('children'),
  memo
)(HelperBase)

export default Helper
