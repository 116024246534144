// Change webpack public path when need to. C3 application uses dynamic public
// when accessing file resources (js, css, etc).

export const hasSuffix = (src, suffix) => src.indexOf(suffix, src.length - suffix.length) !== -1
	, trimSlash = str => str.replace(/^\/+|\/+$/g, '')
	;
// Read https://github.com/webpack/webpack/issues/2776#issuecomment-233208623
function rewriteWebpackPublicPath(pathPrefix) {
	if (!pathPrefix) {
		return;
	}
	const old = __webpack_public_path__
		, trimmedPathPrefix = trimSlash(pathPrefix)
		, publicPath = "/" + trimmedPathPrefix + "/" + trimSlash(old)
		;
	//__webpack_public_path__ = old
	//publicPath + (hasSuffix(old, "/") ? "/" : "");	      
	console.log(
		"changed webpack public path from:"
		, old
		, "to"
		, __webpack_public_path__
	);
}
rewriteWebpackPublicPath(process.env.PATH_PREFIX);