import React from 'react';
import { createRoot } from 'react-dom/client';
import createReactClass from 'create-react-class';
import Avatar from 'react-avatar-edit';
//dev component
import TableComponent from '../../components/TableComponent';
import Textfield from '../../components/CentionTextfield';
import DropDown from '../../components/DropDown';
import adminHelper from '../../components/adminHelper';
//flux store
import AdminStore from '../../flux/stores/adminStore';
import AdminAction from '../../flux/actions/adminActions';
//feature
import Feature from '../../components/feature';
import FileUploader from '../../components/FileUploader';
import { filePathPrefix } from '../../common/v5/helpers';

var AgentAttachment = createReactClass({
	getDefaultProps: function () {
		return {
			items: {},
			deleteFromEditor: false,
			fileToBeDeleted: ""
		}
	},
	getInitialState : function(){
		return{
			items : [],
		}
	},
	componentWillMount: function () {
		this.setState({
			items: this.state.items.concat(this.props.items)
		});
	},
	componentWillReceiveProps: function(nextProps) {
		if(nextProps.deleteFromEditor && nextProps.fileToBeDeleted != "") {
			this.state.items.map(function(item, i){
				if(item.id == nextProps.fileToBeDeleted) {
					this.state.items.splice(i, 1);
				}
			},this);
		}else{
			this.setState({
				items: this.state.items.concat( nextProps.items )
			});
		}
	},
	renderSizeAccordingly: function(showSize) {
		if(showSize < 1000000)
			showSize = "("+(showSize/1000).toFixed(2)+" kb)";
		else
			showSize = "("+(showSize/1000000).toFixed(2)+" mb)";
		return showSize;
	},
	displaySizeContainer: function(origin, totalAttachmentSize) {
		if(origin=="ErrandAttachment") {
			return(
				<div className="totalSizeContainer">{I('Total Attachment Size:')} {this.renderSizeAccordingly(totalAttachmentSize)}</div>
			);
		}
	},
	render: function() {
		var showSize = "";
		var defaultOrigin = "ErrandAttachment";
		var totalAttachmentSize = 0;
		var totalSavedAttachment = 0;
		var origin = (this.props.origin ? this.props.origin : defaultOrigin);
		var maxItemLength = (this.state.items ? this.state.items.length : 0);
		return (
			<div className="attachmentItem">
				<i className="fa fa-paperclip"></i>
				{this.state.items.map(function(item, i){
					showSize = this.renderSizeAccordingly(item.estSize);
					totalAttachmentSize += item.estSize;
					var totalSavedAttachment = 0;
					if(origin=="SavedAttachement") {
						totalSavedAttachment = totalAttachmentSize;
					} else {
						if((i+1) == maxItemLength) {
							totalAttachmentSize += totalSavedAttachment;
						}
					}
					return(
						<span className="file-attach" id={"fileAttach" + item.id} key={i}>
							<a className="underlink" href={ oldWebRoot + item.download}>{item.value}</a><span className="attachmentItemSize"> {showSize}</span>
							<span className="remove-attach" id={item.id}><i onClick={this.onDeleteAttachment.bind(this,item)} id={"iconDelete_"+item.id} className="glyphicon glyphicon-remove" ></i></span>
						</span>
						)
					}.bind(this))
				}
				{this.displaySizeContainer(origin, totalAttachmentSize)}
			</div>
		);
	},
	onDeleteAttachment: function(attachment) {
		this.state.items.splice(this.state.items.indexOf(attachment), 1);
		this.forceUpdate();
		this.props.onHandle(attachment);
	}
});

var AddNewAttachmentBox = createReactClass({
	getDefaultProps: function () {
		return {
			items: {},
			visibleAttachmentBox: true
		}
	},
	render: function(){
		if(!this.props.visibleAttachmentBox)
			return(<span></span>);

		return(
			<div id="AttachFiles">
				<div className="attachFileBrowse">
					<FileUploader uploadTo="errand/notes/uploadAttachment" attachFile={this.props.agentAttachFile} multipleFile={this.props.agentAttachMultipleFile} onDeleteUpload={this.handleDeleteUpload} onProgress={this.handleUploadProgress} onFileupload={this.handleFileUpload} />
				</div>
			</div>
		);
	},
	handleDeleteUpload: function( fid ){
		this.props.onUploadFinish(fid, 'del');
	},
	handleFileUpload: function( fid ){
		this.props.onUploadFinish(fid, 'add');
		document.getElementById("LoadingAttachment").style.display = 'none';
		const container = document.getElementById("ErrandAttachment");
		const root = createRoot(container);
		root.render(React.createElement(AgentAttachment,{
			items: fid,
			onHandle: function( attachment ){
				this.handleDeleteUpload( attachment );
				$.post( webserverRoot + "errand/removeTempAttachment", {fid: attachment.id});
			}.bind(this)
		}
	));
	},
	handleUploadProgress: function( value ){
		document.getElementById("LoadingAttachment").style.display = 'block';
		const container = document.getElementById("LoadingAttachment");
		const root = createRoot(container);
		root.render(<div><img src="img/loading-attachment.gif" /></div>);
	}
});

var AttachmentLink = createReactClass({
	handleClick: function(e){
		this.props.onClick( this );
	},
	render: function() {
		return(
			<span className="icon-attachment" title={I("Attach files")} onClick={this.handleClick}></span>
		);
	}
});

var AddNoteButton = createReactClass({
	handleClick: function(e){
		this.props.onClick( this );
	},
	render: function() {
		return(
			<div className="col-lg-2 col-md-2 col-md-offset-0 col-sm-offset-1 col-sm-1 col-xs-2">
				<Feature tag="notes.client">
					<button className="createButton btn btn-primary btn-sm" type="button" onClick={this.handleClick}>{I('Create new note')}</button>
				</Feature>
			</div>
		);
	}
});

var EditContactCard = createReactClass({
	getInitialState: function(){
		this.channel = [];
		this.groupId = 0;
		this.list = [];
		this.selectedService = "1";
		return{
			groupName: "",
			city: "",
			postcode: "",
			contactText: "",
			externalId: "",
			avatarPreview: "",
			warningLname: "",
			warningCname: "",
			filename: "",
			filetype: "",
			notes: "",
			noteTextInput: "",
			notesButtonDisable: true,
			uploadAttachment: [],
			showAddNotePanel: false,
			editNoteID: 0,
			companies: [],
			companyId: ""
		}
	},
	componentWillMount: function(){
		this.groupId = this.props.groupId;
		if(this.props.errandInfo && this.props.errandInfo.fromAddress != "") {
			this.getGroupIdByAddress(this.props.errandInfo);
		} else {
			AdminAction.getContactById(this.groupId);
			AdminAction.getCustomerNotesById(this.groupId);
		}
		AdminAction.getCompanyList();
		AdminStore.listen(this.onFluxStoreChange);
	},
	componentWillReceiveProps: function(nextProps) {
		if (nextProps.groupId != this.props.groupId || nextProps.errandInfo != this.props.errandInfo ) {
			this.setState({
				groupName: "",
				city: "",
				postcode: "",
				contactText: "",
				warningLname: "",
				externalId: "",
				warningCname: "",
				notes: "",
				noteTextInput: "",
				notesButtonDisable: true,
				uploadAttachment: [],
				showAddNotePanel: false,
				editNoteID: 0,
			});
			this.resetAttachmentBox();
			this.groupId = nextProps.groupId;
			if (nextProps.errandInfo && nextProps.errandInfo.fromAddress != "") {
				this.getGroupIdByAddress(this.props.errandInfo);
			} else {
				AdminAction.getContactById(this.groupId);
				AdminAction.getCustomerNotesById(this.groupId)
			}
		}
	},
	componentWillUnmount: function() {
		AdminStore.unlisten(this.onFluxStoreChange);
	},
	componentDidMount: function() {
		$('.externalexpert-page').css({'opacity':0.5});
	},
	onFluxStoreChange: function(state) {
		if(state.contactSrc) {
			this.channel = state.contactSrc.channel;
			var customerName = (state.contactSrc.customer != null ? state.contactSrc.customer.name : "");
			var avatar = (state.contactSrc.customer != null ? state.contactSrc.customer.avatar : "");
			var custExtId = (state.contactSrc.customer != null ? state.contactSrc.customer.externalId : "");
			var city = (state.contactSrc.customer != null ? state.contactSrc.customer.city : "");
			var postcode = (state.contactSrc.customer != null ? state.contactSrc.customer.postcode : "");
			var companyId = (state.contactSrc.customer != null ? state.contactSrc.customer.company: "");
			this.setState({
				groupName: customerName,
				city: city,
				postcode: postcode,
				companyId: companyId == 0 ? "" : companyId.toString(),
				externalId: custExtId,
				list: state.contactSrc.list,
				avatarPreview: avatar,
				noteTextInput: this.state.noteTextInput,
				notesButtonDisable: this.state.notesButtonDisable,
				uploadAttachment: this.state.uploadAttachment
			});
			if(this.props.clientContactCounterUpdated) {
				this.props.clientContactCounterUpdated(state.contactSrc.list ? state.contactSrc.list.length : 0);
			}
		}
		if(state.contactNoteSrc) {
			if (state.contactNoteSrc.data && state.contactNoteSrc.data.notes) {
				this.setState({
					notes: state.contactNoteSrc.data.notes
				});
			}
		}
		if(state.companyList) {
			this.setState({companies: state.companyList});
		}
	},
	handleGroupName: function(e) {
		this.setState({
			groupName: e.target.value,
			warningLname: ""
		});
	},
	handleCity: function(e) {
		this.setState({
			city: e.target.value,
		});
	},
	handlePostcode: function(e) {
		this.setState({
			postcode: e.target.value,
		});
	},
	handleCompany: function(id) {
		this.setState({
			companyId: id
		});
	},
	handleExternalId: function(e) {
		this.setState({
			externalId: e.target.value
		});
	},
	handleContactText: function(e) {
		this.setState({
			contactText: e.target.value,
			warningCname: ""
		});
	},
	saveContactCard: function(event){
		if(this.groupId == 0){
			if(this.state.groupName == "") {
				this.setState({warningLname: I("* You can not leave the field empty.")});
				return
			}
			if(this.state.contactText == ""){
				this.setState({warningCname: I("* You can not leave the field empty.")});
				return
			}
			if(this.selectedService == "1"){
				var emailAddr = this.state.contactText.trim();
				if(!adminHelper.validateEmail(emailAddr)){
					this.setState({warningCname: I("* Please insert correct email address")});
					return
				}
			}else{
				this.setState({warningCname: ""});
			}
		}
		var value = {
			groupId: this.groupId,
			groupName: this.state.groupName,
			city: this.state.city,
			postcode: this.state.postcode,
			externalId: this.state.externalId,
			serviceType: this.selectedService,
			contact: this.state.contactText,
			filename: this.state.filename,
			filetype: this.state.filetype,
			companyId: this.state.companyId
		};
		$.post(webserverRoot + "admin/contactcards/entry", value)
		.done(function(rs){
			if(typeof rs.error != "undefined") {
				console.log(rs.error);
			}else if(typeof rs.failed != "undefined") {
				this.setState({warningLname: rs.failed});
			}else if(typeof rs.customer != "undefined") {
				this.setState({warningLname: ""});
				AdminAction.getContacts("");
				this.groupId = rs.customer.id;
				if (this.props.onSave) {
					this.props.onSave(this.groupId);
				}
				AdminAction.getContactById(this.groupId);
				if(typeof this.props.handleErrandThreadsUpdated !=='undefined') {
					this.props.handleErrandThreadsUpdated();
				}
			}
		}.bind(this));
	},
	handleItemDeleteAction: function(action){
		$.ajax({
			type: "DELETE",
			url: action,
			cache: false,
			success: function(){
				if(typeof this.props.handleErrandThreadsUpdated !== 'undefined') {
					this.props.handleErrandThreadsUpdated();
				}
				AdminAction.getContactById(this.groupId);
			}.bind(this)
		});
	},
	handleNoteDeleteAction: function(action){
		var fd = new FormData();
		fd.append("type", "contactcard");
		fd.append('groupid', this.groupId);
		$.ajax({
			type: "DELETE",
			url: action,
			data: fd,
			processData: false,
			contentType: false,
			cache: false,
			success: function(){
				AdminAction.getCustomerNotesById(this.groupId);
			}.bind(this)
		});
	},

	getGroupIdByAddress: function(errandInfo) {
		var url = webserverRoot + 'errand/getClientByAddress';
		var data = {
			errandId: errandInfo.errandId
		};
		$.get( url, data)
		.done(function(value) {
			value.mcam.channels.forEach(function(d){
				if(typeof d.type != undefined && d.type == "Result" ){
					var content = JSON.parse(d.content);
					this.groupId = content.result;
					AdminAction.getContactById(this.groupId);
					if(typeof content.result == 'undefined' || content.result == 0) {
						this.selectedService = ((errandInfo.service && errandInfo.service != 2 && errandInfo.service != 3) ? (errandInfo.service == 8 ? "7" : errandInfo.service.toString()) : "1");
						this.setState({
								contactText: errandInfo.fromAddress,
								groupName:(errandInfo ? errandInfo.fromName : "")
						});
					}
				}
			}.bind(this));
		}.bind(this));
	},
	parentIsSameOrigin: function(){
		var result = true;
		try {
			result = window.parent.location.host == window.location.host;
		} catch (e) {
			result = false;
		}
		return result;
	},
	handleAvatarCrop: function(preview){
		this.setState({avatarPreview: preview});
	},
	handleAvatarUpload: function(){
		var fd = new FormData();
		var blob = this.dataURItoBlob(this.state.avatarPreview);
		fd.append("uploadedAvatar", blob);
		$.ajax({
			url: filePathPrefix() + "errand/uploadClientAvatar",
			data: fd,
			processData: false,
			contentType: false,
			type: 'POST',
			success: function(rd){
				this.setState({filename: rd.filename, filetype: rd.filetype});
			}.bind(this)
		});
	},
	dataURItoBlob: function(dataURI){
		var byteString;
		if (dataURI.split(',')[0].indexOf('base64') >= 0)
			byteString = atob(dataURI.split(',')[1]);
		else
			byteString = unescape(dataURI.split(',')[1]);

		var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
		var ia = new Uint8Array(byteString.length);
		for (var i = 0; i < byteString.length; i++) {
			ia[i] = byteString.charCodeAt(i);
		}
		return new Blob([ia], {type:mimeString});
	},
	handleRemoveAvatar: function() {
		var fd = new FormData();
		fd.append("id", this.groupId);
        $.ajax({
			url: webserverRoot + "errand/removeClientAvatar",
			data: fd,
			processData: false,
			contentType: false,
			type: 'POST',
			success: function(rd){
				this.setState({avatarPreview: ""});
			}.bind(this)
        });
	},
	handleInputNote(e){
		if(e.target.value != ""){
			this.setState({
				notesButtonDisable: false
			});
		} else {
			this.setState({
				notesButtonDisable: true
			});
		}
		this.setState({
			noteTextInput: e.target.value//,
		});
	},
	resetAttachmentBox: function() {
		const errandAttachment = document.getElementById("ErrandAttachment");
		if (errandAttachment) {
			const errandAttachmentRoot = createRoot(errandAttachment);
			if(errandAttachmentRoot) {
				errandAttachmentRoot.unmount()
			}
		}
		const attachPanel = document.getElementById("AttachmentPanel")
		if (attachPanel) {
			const attachmentPanelRoot = createRoot(attachPanel);
			if(attachmentPanelRoot) {
				attachmentPanelRoot.unmount();
			}
		}
	},
	handleAddNote(){
		var fd = new FormData();
		fd.append("type", "contactcard");
		fd.append("groupid", this.groupId);
		fd.append("text", this.state.noteTextInput);
		fd.append("attachments", this.state.uploadAttachment);
        $.ajax({
			url: webserverRoot + "errand/notes/save",
			data: fd,
			processData: false,
			contentType: false,
			type: 'POST',
			success: function(){
				AdminAction.getCustomerNotesById(this.groupId);
				this.setState({
					noteTextInput: "",
					uploadAttachment: [],
					notesButtonDisable: true,
					showAddNotePanel: false
				});
				this.resetAttachmentBox();
			}.bind(this)
		});
	},
	handleCancelAddNote(){
		this.setState({
			showAddNotePanel: false
		});
	},
	handleEditNote: function(){
		var fd = new FormData();
		fd.append("type", "contactcard");
		fd.append("groupid", this.groupId);
		fd.append("note", this.state.editNoteID);
		fd.append("text", this.state.noteTextInput);
		fd.append("attachments", this.state.uploadAttachment);
        $.ajax({
			url: webserverRoot + "errand/notes/save",
			data: fd,
			processData: false,
			contentType: false,
			type: 'POST',
			success: function(){
				AdminAction.getCustomerNotesById(this.groupId);
				this.setState({
					noteTextInput: "",
					uploadAttachment: [],
					notesButtonDisable: true,
					showAddNotePanel: false
				});
				this.resetAttachmentBox();
			}.bind(this)
		});
	},
	handleFileUploadFinish: function( attachment, todo ){
		var attachmentId = attachment.id;
		if(todo === 'add') {
			this.state.uploadAttachment.push(attachmentId);
			this.renderAttachmentPanel();
		}
		if(todo === 'del'){
			if(this.state.uploadAttachment.indexOf(attachmentId) !== -1)
			this.state.uploadAttachment.splice(this.state.uploadAttachment.indexOf(attachmentId), 1);
			if(this.state.uploadAttachment.length == 0) {
				this.resetAttachmentBox();
			}
		}
		this.setState({uploadAttachment: this.state.uploadAttachment})
		console.log(this.state.uploadAttachment);
	},
	renderAttachmentPanel:function() {
		this.visibleAttachmentBox = !this.visibleAttachmentBox;
		const container = document.getElementById("AttachmentPanel");
		const root = createRoot(container);
		return root.render(<AddNewAttachmentBox
			id="fileAttachment"
			visibleAttachmentBox={this.visibleAttachmentBox}
			agentAttachMultipleFile={true}
			onUploadFinish={this.handleFileUploadFinish}
		/>);
	},
	renderAddNote:function() {
		this.setState({
			showAddNotePanel: true,
			editNoteID: 0,
			noteTextInput: "",
			uploadAttachment: [],
			notesButtonDisable: true
		})
		this.visibleAttachmentBox = false;
		this.resetAttachmentBox();
	},
	rowHighlightHandle: function(id){
		if (features["notes.client.agent-edit"]) {
			this.handleEdit(id)
		}
	},
	handleEdit: function(id) {
		let selectedNote = this.state.notes.find(obj => obj.id == id);
		this.setState({
			noteTextInput: selectedNote.note,
			notesButtonDisable: false,
			showAddNotePanel: true,
			editNoteID: id
		});

	},

	render: function(){
		let removeButton = {
			action: webserverRoot + "admin/contactcards/entry/{id}",
			name: I("Remove"),
			actionType:"ajax-request",
			className: "fa fa-remove"
		};
		let removeNoteButton = {
			action: webserverRoot + "errand/notes/{id}",
			name: I("Remove"),
			actionType:"ajax-request",
			className: "fa fa-remove"
		};
		let column = [
				{header: I("Contact for"), key: "service"},
				{header: I("Contact ID"), key: "address"},
				{header: "", type: "button", key: "service", data: removeButton}
		];
		let noteColumn = [
			{header: I("Created"), key: "username"},
			{header: I("Date"), key: "writtenDate"},
			{header: I("Note"), key: "note"},
			{header: I("Attachment"), type: "attachment", key: "attachments"},
	];
	if (features["notes.client.agent-edit"]) {
		noteColumn.push({header: "", type: "button", data: removeNoteButton});
	}
	let contactListStyle = {paddingTop: "10px"}, uploadAvatar = "", avatarPreview = "";
	let setProfilePic  = "";
	if(this.props.popup) {
		var containerHeight = 400;
		if(this.parentIsSameOrigin() == true){
			var containerHeight = parent.innerHeight - 400;
		}
		contactListStyle = {maxHeight : containerHeight,overflowY : "auto", paddingTop: "10px"}
	}
	if(this.state.avatarPreview != ""){
		avatarPreview = <img src={this.state.avatarPreview} />
		if(this.state.avatarPreview.length > 200) {
			setProfilePic = (
				<span className="pull-right">
					<label className="col-lg-6 control-label" htmlFor="basic">{I('Set')}</label>
					<div className="col-lg-6">
						<input type="checkbox" key={this.groupId} defaultChecked={false} onChange={this.handleAvatarUpload} />
					</div>
				</span>
			);
		}
	}

	uploadAvatar = (
		<div className="form-group">
			<label className="col-lg-3 control-label" htmlFor="basic">{I('Customer avatar')}</label>
			<div className="col-lg-3" style={{marginBottom : "10px"}}>
				<Avatar
					label={I('Choose a file')}
					width={120}
					height={120}
					key={this.groupId}
					onCrop={this.handleAvatarCrop}
					onClose={this.handleAvatarClose}
					mimeTypes="image/jpeg,image/png,image/gif"
					src={""}
				/>
			</div>
			<div className="col-lg-2 col-lg-offset-0">
				<span className="pull-left">
					{avatarPreview}
				</span>
			</div>
			<div className="col-lg-1 col-lg-offset-1">
				{setProfilePic}
			</div>
			{this.state.avatarPreview != "" && <div className="col-lg-1 pull-right">
				<span className="pull-right">
					<button className="btn btn-primary btn-sm" type="button" onClick={this.handleRemoveAvatar}>{I('Remove avatar')}</button>
				</span>
			</div>}
			<div className="clear"></div>
		</div>
	);
	return(
		<div className="panel panel-default">
			<div className="panel-heading">
				{I("Manage Contact Card")}
			</div>
			<div className="panel-body">
				<div className="form-group">
					<label className="col-lg-3 control-label" htmlFor="basic"><span className="mandatory">* </span>{I('Contact Name')}</label>
					<div className="col-lg-9">
						<input className="form-control input-sm" value={this.state.groupName} onChange={this.handleGroupName} />
						<span className="with-errors-lname" style={{color: 'red'}}>{this.state.warningLname}</span>
					</div>
					<div className="clear"></div>
				</div>
				<div className="form-group">
					<label className="col-lg-3 control-label" htmlFor="basic">{I('Postcode')}</label>
					<div className="col-lg-4">
						<input className="form-control input-sm" value={this.state.postcode} onChange={this.handlePostcode} />
					</div>
					<label className="col-lg-1 control-label" htmlFor="basic">{I('City')}</label>
					<div className="col-lg-4">
						<input className="form-control input-sm" value={this.state.city} onChange={this.handleCity} />
					</div>
					<div className="clear"></div>
				</div>
				<div className="form-group">
					<label className="col-lg-3 control-label" htmlFor="basic">{I('Company')}</label>
					<div className="col-lg-8">
						<DropDown id="CompanySelect"
							textChooseItem={I("Select Company")}
							selectedItems={this.state.companyId}
							items={this.state.companies}
							fields={{id:"id", name:"name"}}
							align="center"
							class="fourthColumn"
							onChange={this.handleCompany}
							wantDeselect={true}
						/>
					</div>
					<div className="clear"></div>
				</div>
				<div className="form-group">
					<label className="col-lg-3 control-label" htmlFor="basic"><span className="mandatory">* </span>{I('Contact for')}</label>
					<div className="col-lg-4">
					<DropDown id="ChannelSelect"
						selectedItems={this.selectedService}
						items={this.channel}
						fields={{id:"type", name:"name"}}
						align="center"
						class="fourthColumn"
						onChange={
							function(id) {
								this.selectedService = id;
							}.bind(this)
						}
					/>
					</div>
					<div className="col-lg-3">
						<span className="pull-left">
							<input className="form-control input-sm" id="contactCardText" placeholder="Enter text" value={this.state.contactText} onChange={this.handleContactText}/>
							<span className="with-errors-lname" style={{color: 'red'}}>{this.state.warningLname}</span>
						</span>
					</div>
					<div className="clear"></div>
					<div className="row">
						<div className="col-lg-3"></div>
						<div className="col-lg-3"></div>
						<div className="col-lg-6">
							<span className="with-errors-cname" style={{color: 'red'}}>{this.state.warningCname}</span>
						</div>
					</div>
				</div>
				<div className="form-group">
					<label className="col-lg-3 control-label" htmlFor="basic">{I('External ID')}</label>
					<div className="col-lg-9">
						<input className="form-control input-sm" value={this.state.externalId} onChange={this.handleExternalId} />
					</div>
					<div className="clear"></div>
				</div>
				{uploadAvatar}
				<div className="form-group">
					<div className="col-lg-12">
						<div className="col-lg-6">
							<span className="pull-right" style={{marginLeft: '5px'}}>
								<button className="btn btn-primary btn-sm"
									data-qa-id={"QA_cc_edit_save_btn"}
									type="button" onClick={this.saveContactCard}>
										{I('Save')}
								</button>
							</span>
							<span className="pull-right">
								{!this.props.popup ?
									<button className="btn btn-warning btn-sm"
										data-qa-id={"QA_cc_edit_cancel_btn"}
										type="button" onClick={this.props.onClose}>
											{I('Cancel')}
									</button>: ""
								}
							</span>
						</div>
						<div className="col-lg-6"></div>
					</div>
				</div>
				<div className="clear"></div>
				{this.state.list && this.state.list.length > 0 ?
					<div className="contactCardListTable" style={contactListStyle}>
						<TableComponent
							dataSource={this.state.list}
							columns={column}
							itemDeleteAction={this.handleItemDeleteAction}
							id={'ContactTable'}
						/>
					</div>
					: ""
				}
				<div className="noteDiv">
					<h3>{I("Notes")}</h3>
					{<AddNoteButton onClick={this.renderAddNote}/>}
					<div id="AddNotePanel" hidden={!this.state.showAddNotePanel}>
						{this.state.editNoteID && this.state.editNoteID > 0 ?
							<div className="add-note">
								<h4>{I("Edit note")}</h4>
								<textarea value={this.state.noteTextInput} data-qa-id={"QA_cc_addNote"}
									style={{width: '100%', height: "80px", resize:'none'}}
									onChange={this.handleInputNote}>
								</textarea>
								<div className="attachment-icon">
									{<AttachmentLink onClick={this.renderAttachmentPanel} />}
									<div id="AttachmentPanel">
									</div>
									<span id="LoadingAttachment" style={{display:'none'}}></span>
									<div className="errand-attachment" id="ErrandAttachment"></div>
								</div>
								<div className="pull-right">
									<button className="btn-blue"
										data-qa-id={"QA_cc_addNoteCancel_btn"}
										onClick={this.handleCancelAddNote}>
										{I("Cancel")}
									</button>
									<button className="btn-blue" disabled={this.state.notesButtonDisable}
										data-qa-id={"QA_cc_addNote_btn"}
										onClick={this.handleEditNote}>
										{I("Save")}
									</button>
								</div>
							</div>
						:
							<div className="add-note">
								<h4>{I("Add note to contact card")}</h4>
								<textarea value={this.state.noteTextInput} data-qa-id={"QA_cc_addNote"}
									style={{width: '100%', height: "80px", resize: 'none'}}
									onChange={this.handleInputNote}>
								</textarea>
								<div className="attachment-icon">
									{<AttachmentLink onClick={this.renderAttachmentPanel} />}
									<div id="AttachmentPanel">
									</div>
									<span id="LoadingAttachment" style={{display:'none'}}></span>
									<div className="errand-attachment" id="ErrandAttachment"></div>
								</div>
								<div className="pull-right">
									<button className="btn-blue"
										data-qa-id={"QA_cc_addNoteCancel_btn"}
										onClick={this.handleCancelAddNote}>
										{I("Cancel")}
									</button>
									<button className="btn-blue" disabled={this.state.notesButtonDisable}
										data-qa-id={"QA_cc_addNote_btn"}
										onClick={this.handleAddNote}>
										{I("Save")}
									</button>
								</div>
							</div>
						}
					</div>
					<div className="contact-card-content">
						<div className="notes">
							{this.state && this.state.notes && this.state.notes.length > 0 ?
								<div className="note-list" ref="noteList">
									<TableComponent
										dataSource={this.state.notes}
										columns={noteColumn}
										itemDeleteAction={this.handleNoteDeleteAction}
										id={'NoteTable'}
										highlightRow={this.rowHighlightHandle}
									/>
								</div>
								: ""
							}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
	}
});

export default EditContactCard;
