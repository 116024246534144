let path = "/ng/", menuList, _cloudFront = process.env.CLOUDFRONT_URL;

if(typeof process.env.PATH_PREFIX !== 'undefined') {
	path = process.env.PATH_PREFIX;
}

if(typeof cloudFrontURL === 'undefined') {
	_cloudFront = process.env.CLOUDFRONT_URL;
}

if(typeof menu !== 'undefined') {
	menuList = menu;
}

function translate(k) {
	if (typeof TranslationDictionary !== 'undefined') {
		const translated = TranslationDictionary[k];
		if (translated) {
			return translated;
		}
	}
	return k;
}

const { origin, protocol, hostname } = window.location;

function urlForApplicationAction(handler) {
	// TODO: protocol and hostname can be shorten to use origin
	return protocol + "//" + hostname + "/" + handler;
}

// handle all global scopes here
const originPath = origin + path
	, webserverRoot = originPath + "Cention/web/"
	;
(function() {
	if (typeof _cloudFront !== 'undefined' && _cloudFront.length > 5) {
		window.CKEDITOR_BASEPATH = _cloudFront + "/vendor/ckeditor/";
	} else {
		window.CKEDITOR_BASEPATH = originPath + "/vendor/ckeditor/";
	}
	if (!window.webserverRoot) {
		window.webserverRoot = webserverRoot;
	}

	// I and L function
	if (!window.I) {
		window.I = translate;
	}
	if (!window.L) {
		window.L = translate;
	}

	if (!window.urlForApplicationAction) {
		window.urlForApplicationAction = urlForApplicationAction;
	}
})();

module.exports = {
	I: translate,
	// L() is equivalent in purpose to c3/i18n/i18n.go's Translator.L()
	L: translate,
	menu: menuList,
	webRoot: webserverRoot,
	cloudFrontURL: process.env.CLOUDFRONT_URL,
	pathPrefix: process.env.PATH_PREFIX
};
