import React from 'react';
import createReactClass from 'create-react-class';

var PrintErrandHistory = createReactClass({
	getInitialState: function() {
		return {
            printInfo: "",
            printContent: ""
        };
	},
	componentWillMount: function(){
        $.get( webserverRoot + "errand/printErrandHistory", {errand: parseInt(this.props.errandList)})
        .done(function(data){
            this.setState({
                printInfo: data["printInfo"],
                printContent: data["content"]
            });
        }.bind(this));
    },
    render: function() {
        return(
            <div>
                <div style={{marginBottom:"8px"}}>
                    {this.state.printInfo}
                </div>
                <div dangerouslySetInnerHTML={{__html: this.state.printContent}} />
            </div>
        )
    }
});
export default PrintErrandHistory;
