import createReactClass from 'create-react-class';
import React from 'react';

var ExpiryText = createReactClass({
	expiryText: function(duration, timestamp) {
		// TODO use a library to do this.
		if (timestamp == 0) {
			return I("Expired");
		}
		var secs = duration - ((new Date()).getTime()/1000 - timestamp);
		if (secs <= 0) {
			return I("Expired");
		}
		if (secs <= 60) {
			return I("Expiring soon");
		}
		if (secs <= 3600) {
			return I("Expiring in {N} minutes").replace('{N}', Math.round(secs/60));
		}
		var hours = secs / 3600;
		if (hours < 24) {
			return I("Expires in {N} hours").replace('{N}', Math.round(hours));
		}
		if (hours < 48) {
			return I("Expires tomorrow");
		}
		var days = hours / 24;
		return I("Expires in {N} days").replace('{N}', Math.round(days));
	},
	render: function() {
		return <span>{this.expiryText(this.props.duration, this.props.timestamp)}</span>
	},
});

export var DataProtection = createReactClass({
	getInitialState: function() {
		this.TimeZones = null;
		this.langs = [
			{ value: "en", label: I("English") },
			{ value: "fi", label: I("Finnish") },
			{ value: "nb", label: I("Norwegian") },
			{ value: "sv", label: I("Swedish") },
		];
		return {
			Path: "",
			Secret: "",
			TimestampEnabled: 0,
			Logs: [],
			Error: "",
			ExpirySecs: 0,

			selectedTZ: -1,
			selectedLang: "en",

			working: false,
			expanded: false,
			ready: false,

			checkedToForget: false,
			anonymizeTaskId: 0,
			anonymizeTaskStatus: 0, // 0-Init, 1-In progress, 2-Completed, 3-Error
			anonymizeError: ""
		}
	},
	componentDidMount: function() {
		this.getCurrentAnonymizeTask();
	},
	exportContacts: function(errandId) {
		var that = this;
		this.setState({
			Error: "",
			Path: "",
			Secret: "",
			working: true,
		});
		$.ajax(webserverRoot+"contact/export/"+errandId, {
			"type": "POST",
			"contentType": "application/x-www-form-urlencoded",
			"data": {
				lang: this.state.selectedLang,
				tz: this.state.selectedTZ
			},
			"complete": function(jqXHR, status) {
				var logs = [];
				// TODO show spinner
				var exportState = {
					Error: "",
					Path: that.state.Path,
					Secret: that.state.Secret,
					TimestampEnabled: 0,
					ExpirySecs: 0,
					Logs: that.state.Logs,
				};
				if (status === "success") {
					var r = JSON.parse(jqXHR.responseText);
					exportState.Error = r.Error;
					exportState.Path = r.Path;
					exportState.Secret = r.Secret;
					exportState.TimestampEnabled = r.TimestampEnabled;
					exportState.ExpirySecs = r.ExpirySecs;
					if (r.Log) {
						logs.push(r.Log);
						for(i=0;i<that.state.Logs.length;i++) {
							logs.push(that.state.Logs[i]);
						}
						exportState.Logs = logs;
					}
				} else {
					exportState.Error = I('Export failed, please try again.');
				}
				that.setState({
					Error: exportState.Error,
					Path: exportState.Path,
					Secret: exportState.Secret,
					TimestampEnabled: exportState.TimestampEnabled,
					ExpirySecs: exportState.ExpirySecs,
					Logs: exportState.Logs,
					working: false,
				});
			}
		});
	},
	revokeAccess: function(errandId, secret) {
		var that = this;
		this.setState({
			Error: "",
			working: true,
		});
		$.ajax(webserverRoot+"contact/export/"+errandId+"/"+secret, {
			"type": "DELETE",
			"contentType": "application/json",
			"complete": function(jqXHR, status) {
				var logs = []
				, i
				;
				// TODO show spinner
				var exportState = {
					Error: "",
					Logs: that.state.Logs,
					Path: that.state.Path,
				};
				if (status === "success") {
					var r = JSON.parse(jqXHR.responseText);
					exportState.Error = r.Error;
					if (r.Log) {
						logs.push(r.Log);
						for(i=0;i<that.state.Logs.length;i++) {
							logs.push(that.state.Logs[i]);
						}
						exportState.Logs = logs;
						exportState.Path = "";
					}
				} else {
					exportState.Error = I('Revoke failed, please try again.');
				}
				that.setState({
					Error: exportState.Error,
					Logs: exportState.Logs,
					Path: exportState.Path,
					working: false,
				});
			}
		});
	},
	getExportHistory: function(errandId) {
		var that = this;
		$.ajax(webserverRoot+"contact/exportlog/"+errandId, {
			"type": "POST",
			"data": {wantTimeZones:!that.TimeZones},
			"contentType": "application/x-www-form-urlencoded",
			"complete": function(jqXHR, status) {
				// TODO show spinner
				if (status === "success") {
					var r = JSON.parse(jqXHR.responseText)
					, st = {
						ready: true,
						Path: r.Path,
						Secret: r.Secret,
						TimestampEnabled: r.TimestampEnabled,
						ExpirySecs: r.ExpirySecs,
						selectedTZ: r.TimeZoneId,
						selectedLang: r.LangCode,
						Error: r.Error,
					}
					;
					if (r.TimeZones) {
						that.TimeZones = r.TimeZones;
					}
					if (r.Logs) {
						st.Logs = r.Logs;
					}
					that.setState(st);
				} else {
					that.setState({
						ready: true,
						Error: I('Could not retrieve data')
					})
				}
			}
		});
	},
	isAnonymized: function() {
		return (this.state.anonymizeTaskId != 0 && this.state.anonymizeTaskStatus != 3);
	},
	AnonymizeContacts: function(){
		var ok = window.confirm(I("You are deleting all data and conversations with this person. If you proceed there is no way of reversing the action"));
		if(ok) {
			$.post(webserverRoot + "contact/anonymize", {
				errandId: this.props.errandId
			})
			.done(function(rs){
				this.getExportHistory(this.props.errandId);
				if (rs && rs.result) {
					this.setState({
						anonymizeTaskId: rs.result.id,
						anonymizeTaskStatus: rs.result.status,
						anonymizeError: rs.result.error
					})
				}
			}.bind(this));
		}
	},
	getCurrentAnonymizeTask: function() {
		$.get(webserverRoot + 'contact/anonymize', {
			errandId: this.props.errandId
		})
		.done(function(rs) {
			if (rs && rs.result) {
				this.setState({
					anonymizeTaskId: rs.result.id,
					anonymizeTaskStatus: rs.result.status,
					anonymizeError: rs.result.error
				})
			}
		}.bind(this));
	},
	handleCheckToForget: function() {
		this.setState({
			checkedToForget: !this.state.checkedToForget
		})
	},
	renderRightToForgotten: function() {
		if (F("data-protection.anonymize-by-agent") && !this.isAnonymized()) {
			return(
				<div>
					<hr/>
					<div className="pull-left">
						<input type="checkbox" checked={this.state.checkedToForget} onChange={this.handleCheckToForget}/> {I("Rights to be Forgotten")}
					</div>
					<button className={"btn btn-danger btn-sm pull-right" + (this.state.checkedToForget ? "":" disabled")} type="button" onClick={this.AnonymizeContacts}>{I('Anonymize Contact')}</button>
					<div className="clear"></div>
					{!this.state.anonymizeError?null:
					<div className="error">
						{this.state.anonymizeError}
					</div>
					}
				</div>);
		}
		return null;
	},
	handleChangeLanguage: function(e) {
		this.setState({
			selectedLang: e.target.value
		});
	},
	handleChangeTZ: function(e) {
		this.setState({
			selectedTZ: e.target.value
		});
	},
	renderLangAndTimeZoneSelect: function() {
		if (this.state.Path != "") {
			return null;
		}

		var langOptions = [];
		langOptions.push(<option key={-1} value="">{I("Language")}</option>);
		for (var i=0;i<this.langs.length;i++) {
			langOptions.push(<option
				key={i}
				value={this.langs[i].value}
				>
				{this.langs[i].label}
			</option>);
		}
		var langSelect = <select name="language"
			style={{marginRight: "5px"}}
			onChange={this.handleChangeLanguage}
			value={this.state.selectedLang}
			>
			{langOptions}
		</select>

		var timeZonesSelect;
		var options = [];
		var tzs = this.TimeZones;
		if (tzs) {
			options.push(<option key={-1}>{I("Time Zone")}</option>);
			for (var i=0;i<tzs.length;i++) {
				options.push(<option
					key={i}
					value={tzs[i].Id}
					>
					{tzs[i].Name}
				</option>);
			}
			timeZonesSelect = <select
				name="timezone"
				style={{marginRight: "5px"}}
				onChange={this.handleChangeTZ}
				value={this.state.selectedTZ}
				>
				{options}
			</select>;
		}
		return <span>
			{langSelect}
			{timeZonesSelect}
		</span>;

	},
	render: function() {
		var that = this
		, panelBodyStyle = {
			display: 'none',
		}
		, history = []
		, i
		, log
		, text
		, logDiv
		;


		if (this.state.expanded) {
			panelBodyStyle.display = 'block';

			for(var i=0; i<this.state.Logs.length; i++) {
				log = this.state.Logs[i];
				switch (log.Action) {
				case "GRANT":
					text = I("Access granted by {AGENTNAME} at {DATE}");
					break;
				case "REVOKE":
					text = I("Access revoked by {AGENTNAME} at {DATE}");
					break;
				case "ANONYMIZE":
					text = I("Anonymized by {AGENTNAME} at {DATE}");
					break;
				}
				if (text) {
					text = text
						.replace('{AGENTNAME}', log.AgentName)
						.replace('{DATE}', log.TimestampStr)
						;

					history.push(<div key={i}>{text}</div>);
				}
			}

			if (this.state.ready && history.length > 0) {
				logDiv = <div style={{overflow:"auto", maxHeight: "80px"}}>
					{this.state.Path==""
					? (this.state.working
						? <div>{I("Please wait...")}</div>
						: null)
					: <div>
						<a href={this.state.Path} target="_blank">
							{I("Customer data is available here.")}
						</a> <ExpiryText timestamp={this.state.TimestampEnabled} duration={this.state.ExpirySecs}/>
					</div>
					}
					{history}
				</div>;
			}
		}


		var langAndTimezone = this.renderLangAndTimeZoneSelect()

		var exportContactHistoryAndLink;
		if (this.state.expanded) {
			if (this.state.ready) {
				if (F("data-protection.export")) {
					exportContactHistoryAndLink = <div>
						{logDiv}
						{logDiv?<hr/>:null}
						<div className="pull-left">{I("Data Portability")}</div>
						<div className="pull-right">
							{langAndTimezone}
							<button
								className="btn btn-primary btn-sm"
								onClick={function(){
									if (that.state.Path=="") {
										that.exportContacts(that.props.errandId);
									} else {
										that.revokeAccess(that.props.errandId, that.state.Secret);
									}
								}}>
								{this.state.Path==""
								? I("Grant access")
								: I("Revoke access")
								}
							</button>
						</div>
						<div className="clear"></div>
					</div>;
				}
			} else {
				exportContactHistoryAndLink = I("Please wait...");
			}
		}

		// TODO hide if contact is anonymized.
		return <div className="panel panel-default">
			<div className="panel-heading">
				<a href="#" onClick={function(e){
						if (!that.state.expanded) {
							that.getExportHistory(that.props.errandId);
						}
						that.setState({
							expanded:!that.state.expanded,
							checkedToForget: false
						});
						e.preventDefault();
					}}
				>
					{this.state.expanded?I("Data Protection"):I("Data Protection ...")}
				</a>
			</div>
			<div className="panel-body" style={panelBodyStyle}>
				{exportContactHistoryAndLink}
				{!this.state.Error?null:
				<div className="error">
					{this.state.Error}
				</div>
				}
				{this.renderRightToForgotten()}
			</div>
		</div>
	}
});
