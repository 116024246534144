import React from 'react'
import classNames from 'classnames'
import styled from 'styled-components'
import { useCallbackWithValue, useDisableableCallback } from '../hooks/callback'

const StyledLabel = styled.label`
  &.toggle-switch {
    display: inline-block;
    vertical-align: middle;
    cursor: ${({ disabled }) => disabled ? 'not-allowed' : 'pointer'};
    .jq-checkbox {
      input[type="checkbox"] {
        display: none;
      }
      &.checked {
        .jq-checkbox__div:before {
          left: 11px;
          background-color: #00cc77;
        }
      }
      &.enabled {
        .jq-checkbox__div:before {
          left: 5px;
          background-color: #ffaf11;
        }
      }
    }
    .jq-checkbox__div {
      display: block;
      width: 27px;
      height: 15px;
      border-radius: 15px;
      border: 1px solid #00000033;
      position: relative;
      &:before {
        content: '';
        position: absolute;
        width: 15px;
        height: 15px;
        left: -1px;
        top: -1px;
        border-radius: 15px;
        background-color: #ff0000;
        transition: background-color .2s ease, left .2s ease;
      }
    }
  }
`
const SwitchCheckbox = ({
  active,
  buttonClassName,
  className,
  disabled,
  id,
  inputId,
  name,
  onClick
}) => (
  <StyledLabel
    className={classNames(className, 'toggle-switch')}
    disabled={disabled}
  >
    <div
      className={classNames(
        {
          'jq-checkbox': !buttonClassName,
          checked: !buttonClassName && active
        },
        buttonClassName
      )}
    >
      <input id={inputId} type='checkbox' name={name} />
      <div
        className='jq-checkbox__div'
        data-qa-id={'QA_' + id}
        onClick={useDisableableCallback(
          disabled,
          useCallbackWithValue(active, onClick)
        )}
      />
    </div>
    <span className='switch' />
  </StyledLabel>
)

export default SwitchCheckbox

export const PlainSwitchCheckbox = styled(SwitchCheckbox)`
  label.toggle-switch& {
    line-height: normal;
    margin: 0px 6px;
    vertical-align: unset;
  }
`
