import React from 'react';
import createReactClass from 'create-react-class';
//dev component
import FileUploader from './FileUploader';
import DropDown from './DropDown';
import DropDownItem from './DropDownItem';
import AnswerReplyOptions from './AnswerReplyOptions';
import Feature from './feature';

var ErrandNotes = createReactClass({
    getInitialState: function() {
        return {
            noteItems:[],
            currentNote: "",
            currentAttachments: [],
            readyForsave: false,
            saving: false,
            deleting: -1,
            deletingAttachments: -1,
            loadingNotes: false
        }
    },
    componentDidMount: function(){
        this.state.loadingNotes = true;
        this.setState(this.state);
        this.doLoadNotes().then(function(data){
            for(i=0;i<data.notes.length;i++){
                this.state.noteItems.push(data.notes[i]);
            }
            this.state.loadingNotes = false;
            this.setState(this.state);
        }.bind(this))
        .fail(function(dat){
            this.state.loadingNotes = false;
            this.setState(this.state);
        }.bind(this));
    },
    bytesToSize: function(bytes) {
        var sizes = ['bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes == 0) return 'n/a';
        var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
        if (i == 0) return bytes + ' ' + sizes[i];
        return (bytes / Math.pow(1024, i)).toFixed(1) + ' ' + sizes[i];
    },
    doSaveNotes: function(){
        var attach = [];
        this.state.currentAttachments.map(function(item,i){
            attach.push(item.id);
        });
        return $.post( webserverRoot + 'errand/notes/save',{errand: this.props.errandId,type: this.props.type,text:this.state.currentNote,attachments:attach.length > 0 ? attach.join(',') : ""});
    },
    doDeleteNotes: function(id){
        return $.post( webserverRoot + 'errand/notes/delete',{errand: this.props.errandId,type: this.props.type,note:id});
    },
    doDeleteAttachements: function(id){
        return $.post( webserverRoot + 'errand/notes/deleteAttachment',{fid: id});
    },
    doLoadNotes: function(){
        return $.post( webserverRoot + 'errand/notes/list',{errand:this.props.errandId,type: this.props.type});
    },
    findItemIndex: function(items,id){
        var found = -1;
        items.map(function(item, i){
            if(item.id==id){
                found = i;
            }
        },this);
        return found;
    },
    handleRemoveNote: function(item){
        this.state.deleting = item[this.props.fields.id];
        this.setState(this.state);
        this.doDeleteNotes(item[this.props.fields.id]).then(function(data){
            this.state.noteItems.splice(this.findItemIndex(this.state.noteItems,item[this.props.fields.id]),1);
            this.state.deleting = -1;
            this.setState(this.state);
            if(this.props.notesCounterUpdated) this.props.notesCounterUpdated(this.state.noteItems.length);
        }.bind(this))
        .fail(function(dat){
            this.state.deleting = -1;
            this.setState(this.state);
        }.bind(this));

    },
    handleSaveNote: function(){
        if(!this.state.readyForsave || this.state.saving){
            return;
        }
        this.state.saving = true;
        this.setState(this.state);
        this.doSaveNotes().then(function(data){
            this.state.noteItems.unshift(data.note);
            this.state.saving = false;
            this.setState(this.state);
            if(this.props.notesCounterUpdated) this.props.notesCounterUpdated(this.state.noteItems.length);
        }.bind(this))
        .fail(function(dat){
            this.state.saving = false;
            this.setState(this.state);
        }.bind(this));
    },
    handleNoteAttachment: function(){
        console.log('handling note attachment');
    },
    handleChangeNoteText: function(e){
        if(e.target.value != ""){
            this.state.readyForsave = true;
            this.state.currentNote = e.target.value;
        } else {
            this.state.readyForsave = false;
            this.state.currentNote = e.target.value;
        }
        this.setState(this.state);
    },
    loadThreadView: function(errand){
        console.log('load thread view');
    },
    loadNotesView: function(thread){
        console.log('load notes view');
    },
    handleUploadAttachement: function(attachment){
        this.state.currentAttachments.push(attachment);
        this.setState(this.state);
        console.log("handleUploadAttachement",attachment);
    },
    handleCancelAttachement: function(attachment){
        this.state.deletingAttachments = attachment.id;
        this.setState(this.state);

        this.doDeleteAttachements(attachment.id).then(function(data){
            if (event.target.readyState == 4 && event.target.status == 200) {
                this.state.currentAttachments.splice(this.findItemIndex(this.state.currentAttachments,attachment.id),1);
            }
            this.state.deletingAttachments = -1;
            this.setState(this.state);
        }.bind(this))
        .fail(function(dat){
            this.state.deletingAttachments = -1;
            this.setState(this.state);
        }.bind(this));
    },
    loadView: function(type){
            return (
                    <div id="ErrandNotesBoxContent">
                        <Feature tag="notes.client.agent-edit">
                            <div>
                                <div className="row" style={{marginLeft:'0px',marginRight:'0px'}}>
                                    
                                </div>
                                <div className="row buttons-box" style={{marginLeft:'0px',marginRight:'0px'}}>
                                    <div className="btn-group pull-right" role="group">
                                        <FileUploader uploadTo={this.props.uploadAttachmentRoute} onDeleteUpload={this.handleCancelAttachement} onFileupload={this.handleUploadAttachement} />
                                        <a className={"btn btn-success" + (this.state.readyForsave?"":" disabled")} onClick={this.handleSaveNote}><i className={this.state.saving?"fa fa-circle-o-notch fa-spin":"fa fa-save pull-left"}></i> {this.state.saving?I("Saving..."):I("Save")}</a>
                                    </div>
                                </div>
                                <hr className="notes-divider" />
                            </div>
                        </Feature>
                    </div>
                    )
    },
    render: function() {

        var type = this.props.type;
        var errand = this.props.errand;
        var text = this.props.text;
        var attachment = this.props.attachment;
        var notes = this.props.notes;
        var list = this.props.list;
        var note = this.props.note;
        return(
            this.loadView(type)
        )
    }
});
export default ErrandNotes;